.subscription .heading-holder h1 {
    color: #FFF;
    font-size: 48px;
    font-weight: bold;
}

.subscription .heading-holder h2 {
    color: #FF5757;
    font-size: 20px;
}

.subscription .nav-pills {
    background: #484848 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 7px;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    z-index: 999;
    position: relative;
}

.subscription .nav-pills .nav-link {
    font-size: 15px;
    font-weight: 500;
    color: #BEBEBE;
}

.subscription .nav-pills .nav-link.active {
    background: #1A1A1A 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #BEBEBE;
    font-weight: 500;
}
.subscription .med-top{
    padding: 3% 2% 5% 2% !important;
}


@media (min-width:0px) and (max-width:767.98px){
    .subscription .nav-pills .nav-link {
        font-size: 12px;
    }
}