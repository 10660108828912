.Studio_card_one .card-bgg {
    position: relative;
}

.Studio_card_one .card-bgg::after {
    content: "";
    position: absolute;
    background: linear-gradient(0deg, #393939, #000000);
    width: 75%;
    height: 100%;
    border-radius: 20px;
    padding: 20px;
    /* z-index: -9; */
}

.Studio_card_one .card-bgg .sec-1 {
    position: relative;
    z-index: 999;
}

.Studio_card_one .card-bgg .stud-one {
    font-weight: bold;
    font-size: 27px;
    color: #e5e5e5;
}

.Studio_card_one .card-bgg .stud-one span {
    font-weight: normal;
    font-size: 15px;
    color: #e5e5e5;
    margin-left: 20px;
}

.Studio_card_one .card-bgg .starp {
    font-weight: normal;
    font-size: 18px;
    color: #e5e5e5;
    margin-bottom: 0px;
}

.Studio_card_one .card-bgg .staricon {
    color: #ffbe5c;
    font-size: 16px;
}

.Studio_card_one .card-bgg .contett {
    font-weight: normal;
    font-size: 16px;
    color: #c4c4c4;
    margin: 15px 0px 40px 0px;
}

.Studio_card_one .card-bgg .book-btn {
    border-radius: 5px;
    background: #ffbe5c;
    padding: 6px 70px;
    border-radius: 5px;
    border: 0px;
    font-weight: 500;
    font-size: 15px;
    color: #202020;
}

.Studio_card_one .card-bgg .startinfp {
    margin-bottom: 0px;
    margin-left: 15px;
    font-size: 15px;
    color: #bebebe;
}

.Studio_card_one .card-bgg .stud-img {
    width: 100%;
    z-index: 999;
    position: relative;
}


@media (min-width:0px) and (max-width:991px) {
    .Studio_card_one .card-bgg::after {
        width: 100%;
    }

    .Studio_card_one .card-bgg .stud-one {
        font-size: 20px;
    }

    .Studio_card_one .card-bgg .stud-one span {
        font-size: 14px;
    }

    .Studio_card_one .card-bgg .contett {
        font-size: 14px;
    }

    .Studio_card_one .card-bgg .book-btn {
        padding: 5px 40px;
        font-size: 15px;
    }

    .Studio_card_one .card-bgg .startinfp {
        font-size: 14px;
    }
}