.account_section .account_main_div {
    background: #1A1A1A 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 50px;
}

.account_section .account_main_div .acctab_div {
    padding-right: 80px;
}


.account_section .account_main_div .shorttabss {
    background-color: #000;
    border-radius: 5px;
}

.account_section .account_main_div .checcckboxx {
    padding: 6px 20px;
}

.account_section .account_main_div .checcckboxx .form-check {
    font-size: 15px;
    color: #BEBEBE;
}

.account_section .account_main_div .sorttext {
    font-size: 16px;
    color: #BEBEBE;
    font-weight: 500;
    padding-left: 20px;
}

.account_section .account_main_div .checcckboxx .form-check-input {
    background-color: #171717;
}

.account_section .account_main_div .checcckboxx .form-check-input:checked {
    background-color: #171717;
    border-color: #BEBEBE;
    box-shadow: none;
}

.account_section .account_main_div .acctab_div .nav-pills .nav-link {
    color: #BEBEBE;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 400;

}

.account_section .account_main_div .acctab_div .nav-pills .nav-link:hover {
    color: #BEBEBE;
    background-color: #0C0C0C;
}

.account_section .account_main_div .acctab_div .nav-pills .nav-link.active {
    color: #BEBEBE;
    background-color: #0C0C0C;
}

.account_section .account_main_div .acctab_div .flex-column.nav.nav-pills {
    background-color: #171717;
}

.account_section .account_main_div label.form-label {
    color: #BEBEBE;
    font-size: 15px;
}

.account_section .account_main_div label.form-label span {
    color: red;
}

.account_section .account_main_div .displa-instru {
    margin-bottom: 0px;
    font-size: 12px;
    font-style: italic;
    color: #808080;
    margin: 10px 0px;
}

.account_section .account_main_div .form-control {
    background-color: #1A1A1A;
    border: none;
    border-bottom: 1px solid #707070;
    border-radius: 0px;
    color: #808080;
}

.account_section .account_main_div .form-control::placeholder {
    color: #808080;
    font-size: 13px;
}

.smalllabel {
    color: #808080 !important;
    font-size: 12px;
}

.account_section .account_main_div .btnnsub {
    background: #FFBE5C 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    color: #202020;
    padding: 8px 35px;
    border: none;
}

.account_section .account_main_div .react-tel-input .form-control {
    background-color: #1A1A1A;
    border: none;
    border-bottom: 1px solid #707070;
    border-radius: 0px;
    color: #808080;
    width: 100%;
}

.account_section .account_main_div .react-tel-input .flag-dropdown {

    background-color: #1A1A1A;
    border: none;
    border-bottom: 1px solid #707070;
    border-radius: 0px;

}

.account_section .account_main_div .eyediv {
    position: relative;
}

.account_section .account_main_div .eyeicn {
    position: absolute;
    top: calc(50% - 8px);
    right: 20px;
    color: #808080;
}

.account_section .account_main_div .eyeicn2 {
    position: absolute;
    top: calc(50% - 8px);
    left: 20px;
    color: #808080;
}

.account_section .account_main_div .tab2div input#exampleForm\.ControlInput1 {
    padding-left: 50px;
}

.account_section .account_main_div .crdd1_div {
    background: #fff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
}

.account_section .account_main_div .imggdiv {
    background: #E2E2E2 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 160px;
    height: 160px;
    padding: 10px;
}

.account_section .account_main_div .imgg {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.account_section .account_main_div .complettext {
    position: absolute;
    top: 70px;
    right: -24px;
    z-index: 1;
}

.account_section .account_main_div .complettext p {
    transform: rotate(-90deg);
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #BEBEBE;
    margin-bottom: 0px;
    font-size: 15px;
}

.account_section .account_main_div .tabbcard_div {
    position: relative;
    margin-bottom: 40px;
}

.account_section .account_main_div .texttdiv {
    width: -webkit-fill-available;
    padding: 15px 40px 15px 15px;
    border-right: 30px solid green;
    border-radius: 0px 10px 10px 0px;
}

.account_section .account_main_div .stdtext {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
}

.account_section .account_main_div .adrtext {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #202020;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    cursor: pointer;
}
.account_section .account_main_div .adrtext svg{
    margin-right: 5px;
}

.account_section .account_main_div .timee {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #202020;
    cursor: pointer;
}

.account_section .account_main_div .time2 {
    font-weight: 600 !important;
}

.account_section .account_main_div .booktext {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #202020;
    margin-bottom: 0px;
}

.account_section .account_main_div .bookd_div {
    position: absolute;
    width: 94%;
    left: 3px;
    top: -12px;
    background: #FFBE5C 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.account_section .account_main_div .imgrowdivv {
    position: relative;
}

.account_section .account_main_div .imggsssdiv {
    width: 100%;


}



/* second tab css started */
.account_section .account_main_div .imgsidemain {
    height: 100%;
    padding: 15px;
    background: #E2E2E2;
    mix-blend-mode: multiply;
    border-radius: 10px;
}

.account_section .account_main_div .bookiingtab_div {
    position: relative;
    margin-bottom: 30px;
    border-radius: 10px;
    background: #E2E2E2 0% 0% no-repeat padding-box;
}
.account_section .account_main_div .bookiingtab_div .validuptoo{
    position: absolute;
    color: #fff;
    margin-bottom: 0px;
    font-size: 13px;
    right: 0px;
    bottom: 0px;
    transition: 0.3s;
    opacity: 0;
}
.account_section .account_main_div .bookiingtab_div:hover .validuptoo{
    position: absolute;
    color: #fff;
    margin-bottom: 0px;
    font-size: 13px;
    right: 0px;
    bottom: -22px;
    transition: 0.3s;
    opacity: 1;
}

.account_section .account_main_div .booktext_div {
    padding: 20px 20px 20px 20px;

    border-radius: 0px 10px 10px 0px;
    height: 100%;
}

.account_section .account_main_div .datespan {
    background: #FFE8C5 0% 0% no-repeat padding-box;
    border: 1px solid #CF7D00;
    border-radius: 5px;
}

.account_section .account_main_div .bookprice {
    font-size: 15px;
    font-weight: bold;
    color: #202020;
}

.account_section .account_main_div .squrecheckiccn {
    color: #CF7D00;
    font-size: 16px;
}

/* secoond tab css end */

/* forth tab css started */

.account_section .account_main_div .subscrtabdiv {
    position: relative;
    border-right: 30px solid #6642C9;
    padding-left: 55px;
}
.subscrtabdiv2{
    border-right: 30px solid #FF5757 !important;
}

.account_section .account_main_div .forrdivv {
    background-color: #FFBE5C;
}

.account_section .account_main_div .update_div {
    position: absolute;
    top: 70px;
    right: -40px;
    z-index: 1;
}

.account_section .account_main_div .update_div p {
    transform: rotate(-90deg);
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #BEBEBE;
    margin-bottom: 0px;
    font-size: 15px;
}

.account_section .account_main_div .monthly_div {
    position: absolute;
    top: 66px;
    left: -55px;
}

.account_section .account_main_div .monthly_p {
    transform: rotate(-90deg);
    width: fit-content;
    height: fit-content;
    color: #000;
    margin-bottom: 0px;
    font-size: 33px;
    text-transform: uppercase;
    font-weight: 900;
    opacity: 0.2;
    /* transform: matrix(0, -1, 1, 0, 0, 0); */
}
.account_section .no-saved-pay{
    color: #BEBEBE;
    font-size: 14px;
    margin-bottom: 0px;
}

@media(min-width:0px) and (max-width:485px) {
    .account_section .account_main_div {
        padding: 20px;
    }

    .account_section .account_main_div .acctab_div {
        padding-right: 0px;
    }

    .account_section .account_main_div .crdd1_div {
        display: flex;
        flex-wrap: wrap;
    }

    .account_section .account_main_div .imggdiv {
        height: 0px;
        padding: 0px;
    }

    .account_section .account_main_div .texttdiv {
        padding: 10px 10px 10px 10px;
    }

    .account_section .account_main_div .adrtext {
        font-size: 11px;
    }

    .account_section .account_main_div .stdtext {
        font-size: 15px;
    }

    .account_section .account_main_div .timee {
        font-size: 11px;
    }

    .account_section .account_main_div .timee {
        font-size: 12px;
    }

    .account_section .account_main_div .monthly_p {
        font-size: 26px;
    }

    .account_section .account_main_div .monthly_div {
        left: -43px;
    }

    .account_section .account_main_div .subscrtabdiv {

        padding-left: 33px;
    }

    .account_section .account_main_div .acctab_div .nav-pills .nav-link {
        font-size: 13px;
    }

    .account_section .account_main_div label.form-label {

        font-size: 13px;
    }

    .account_section .account_main_div .btnnsub {
        font-size: 12px;
        padding: 6px 25px;
    }

}

@media(min-width:486px) and (max-width:767.98px) {
    .account_section .account_main_div {
        padding: 20px;
    }

    .account_section .account_main_div .acctab_div {
        padding-right: 0px;
    }

    .account_section .account_main_div .texttdiv {
        padding: 15px 10px 15px 10px;
    }

    .account_section .account_main_div .stdtext {
        font-size: 18px;
    }

    .account_section .account_section .account_main_div .texttdiv {
        padding: 10px 10px 10px 10px;
    }

    .account_section .account_main_div .acctab_div .nav-pills .nav-link {
        font-size: 13px;
    }

    .account_section .account_main_div label.form-label {

        font-size: 13px;
    }

    .account_section .account_main_div .btnnsub {
        font-size: 13px;
        padding: 7px 25px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .account_section .account_main_div .acctab_div {
        padding-right: 0px;
    }

    .account_section .account_main_div .texttdiv {
        padding: 15px 10px 15px 10px;


    }

    .account_section .account_main_div .acctab_div .nav-pills .nav-link {
        font-size: 14px;
    }

    .account_section .account_main_div label.form-label {

        font-size: 14px;
    }

    .account_section .account_main_div .btnnsub {
        font-size: 14px;
        padding: 7px 25px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .account_section .account_main_div .acctab_div {
        padding-right: 0px;
    }

    .account_section .account_main_div .texttdiv {
        padding: 15px 10px 15px 10px;
    }

    .account_section .account_main_div .acctab_div .nav-pills .nav-link {
        font-size: 15px;
    }

    .account_section .account_main_div label.form-label {

        font-size: 15px;
    }

    .account_section .account_main_div .btnnsub {
        font-size: 15px;
    }
}

@media (min-width:1201px) and (max-width:1400px) {}