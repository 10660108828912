.main-head-sec {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1024;
}

.main-head-sec .navbar-expand-lg .navbar-collapse {
    justify-content: space-between;
    align-items: flex-end;
}

.main-head-sec .headericn_div {
    height: 27px;
    width: 27px;
    margin-left: 10px;
    background: #a6a6a6 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
}

.main-head-sec .headlogo {
    width: 100%;
    height: 65px;
}

.main-head-sec .userdiv {
    width: 42px;
    height: 42px;
    background: #E9E9E9 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    color: #707070;
    margin: 0px 15px 0px 15px;
}

.main-head-sec .userdiv .dropdown-toggle {
    opacity: 0;
}

.main-head-sec .userdiv .usericn {
    position: absolute;
}

.main-head-sec .glassicndiv {
    margin: 0px 15px 0px 15px;
}

.main-head-sec .userdiv .dropdown-menu {
    left: -100px !important;
    top: 48px;
    width: 212px;
    background: #fff;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    border: 0px;
    border-radius: 0px 0px 5px 5px;
    padding: 7px;
}

.main-head-sec .userdiv .dropdown-menu .dropdown-item {
    border-radius: 5px;
    background: #fff;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 2px;
    font-size: 15px;
    background: linear-gradient(to left, #fff 50%, #FFBE5C 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.3s ease;
    padding: 10px;
}

.main-head-sec .userdiv .dropdown-menu .dropdown-item:hover {
    background-position: left bottom;
}

.main-head-sec .navbar-brand {
    padding-bottom: 0px;
    padding-right: 25px;
}



.main-head-sec {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}

.main-head-sec .navbar-nav .nav-link {
    color: #202020;
    font-size: 15px;
    font-weight: 500;
    margin: auto 11px auto 11px !important;
    border-bottom: 2px solid transparent;
}

.main-head-sec button.accordion-button {
    font-size: 15px;
    font-weight: 500;
    color: #202020;
}

.main-head-sec .accordion-button:focus {
    box-shadow: none;
}

.main-head-sec .accordion-button:not(.collapsed)::after {
    color: black;
    filter: brightness(0);
}



.main-head-sec .navbar-nav .nav-link.active {
    color: #000;
    font-weight: 700;
    border-bottom: 2px solid #000 !important;
    width: fit-content !important;
}

.main-head-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}


.main-head-sec .navbar {
    align-items: end;
    position: relative;
}




.main-head-sec .cart_logo {
    color: #fff;
    font-size: 13px;
}

.main-head-sec .navbar-toggler:focus {
    box-shadow: none;
}

.main-head-sec .droprows1 {
    padding: 50px 0px;
    height: 100%;
    background-color: #a6a6a6;
}

.main-head-sec .droprows2 {
    padding: 50px 20px;
}

.main-head-sec .treat_text {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 1;
}

.main-head-sec .treatname_text {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    text-wrap: wrap;
    margin-bottom: 7px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #202020;
    text-transform: capitalize;
    opacity: 1;
    padding-top: 3px;
    transition: padding 0.3s ease;
}

.main-head-sec .treatname_text:hover {
    padding-top: 0px;
    padding-bottom: 3px;
    /* transition: transform .5s ease-in-out; */
}



.main-head-sec .tabbmains .treattabitem {}

.main-head-sec .tabbmains .treattabitem .treattablink {
    padding: 8px 20px !important;
    margin: 0px !important;
    border-radius: 0px !important;
    color: #fff !important;
    font-family: "Roboto", sans-serif;
}

.main-head-sec .tabbmains .treattabitem .treattablink:hover {
    background-color: #fff;
    color: #000 !important;
    border-right: 7px solid #f9da55;
}

.main-head-sec .tabbmains .treattabitem .treattablink.active {
    background-color: #fff;
    color: #000 !important;
    font-weight: 400 !important;
    border-right: 7px solid #f9da55;
}

/* .main-head-sec   .dpq{
      background-color: #A6A6A6;
    } */

.main-head-sec .login {
    font-size: 18px;
    color: #000;
    padding-left: 16px;
    margin-top: 6px;
}

/* .main-head-sec  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    max-width: 1000px;
    margin-top: -1px;
    width: 100%;
    left: calc(50% - 500px);
  } */

/* .main-head-sec   button.navbar-toggler.collapsed {
      font-size: 16px;
  } */
.main-head-sec span.navbar-toggler-icon {
    font-size: 15px;
}

.main-head-sec .all-icon {
    display: flex;
    align-items: center;
}

.main-head-sec .input-group-box {
    position: absolute;
    top: 51px;
    width: 425px;
    z-index: 1;
    left: -163%;
}

.main-head-sec .input-group-box .form-control {
    border-radius: 0%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    border-radius: 19px;
    font-size: 13px;
}



/* mobile view accord start */
.mobdroppp .accordion-item {
    border: 0px;
}

.mobdroppp button.accordion-button.collapsed {
    padding: 10px 10px 6px 10px;
}

.mobdroppp .accordion-button:not(.collapsed) {
    color: #383838;
    background-color: #fff;
    box-shadow: none;
    padding: 10px 10px 6px 10px;
    /* background-color: var(--bs-accordion-active-bg); */
    /* box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
}

.main-head-sec .mobdroppp .drppp {
    height: 200px;
    overflow-y: scroll;
}

.main-head-sec .mobdroppp .accordion-collapse.collapse.show {
    border: 1px solid #bbbbbb;
    border-radius: 5px;
}
.main-head-sec .navbar-nav .studiodrop.show{
    font-weight: 700;
    border-bottom: 2px solid #000 !important;
}
.main-head-sec .navbar-nav  .active.studiodrop {
    font-weight: 700;
    border-bottom: 2px solid #000 !important;
}
.main-head-sec .navbar-nav .studiodrop .dropdown-menu {
    width: 298px;
    background: #f5f5f5;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
    border: 0px;
    top: 48px;
    border-radius: 0px 0px 20px 20px;
    padding: 10px;
}

.main-head-sec .navbar-nav .studiodrop .dropdown-menu .dropdown-item {
    padding: 7px 0px;
    background: #fff;
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.16));
    border-radius: 5px;
    position: relative;
    border-left: 8px solid #FFBE5C;
    position: relative;
    margin-bottom: 10px;
}

.main-head-sec .navbar-nav .studiodrop .dropdown-menu .dropdown-item::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 15px;
    height: 7px;
    background-color: #FFBE5C;
    z-index: -9;
}

.main-head-sec .navbar-nav .studiodrop .dropdown-menu .dropdown-item::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 15px;
    height: 7px;
    background-color: #FFBE5C;
}

.main-head-sec .navbar-nav .studiodrop .dropdown-menu .dropdown-item .menuimg {
    min-width: 66px;
    height: 45px;
    object-fit: cover;
    border-radius: 0px;
    z-index: 999;
    transition: 0.2s;
}

.main-head-sec .navbar-nav .studiodrop .dropdown-menu .dropdown-item:hover .menuimg {
    transform: scale(1.3);
    transition: 0.2s;
    margin: 0px 15px 0px -7px;
}

.main-head-sec .navbar-nav .studiodrop .dropdown-menu .dropdown-item .menunamediv p {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 15px;
    color: #202020;
}

.main-head-sec .navbar-nav .studiodrop .dropdown-menu .dropdown-item .img-div {
    margin-right: 10px;
}


.main-head-sec .deskviewserchacc {
    display: block;
}

.main-head-sec .mobviewserchacc {
    display: none;
}


/* mobile view accord end */

@media (min-width: 1200px) and (max-width: 1400px) {
    .main-head-sec .navbar-nav .nav-link {
        font-size: 14px;
        margin: 0px 11px 0px 11px;
    }
    .main-head-sec .userdiv {
        width: 35px;
        height: 35px;
        font-size: 20px;
    }

    .main-head-sec button.accordion-button {
        font-size: 14px;
    }

    .main-head-sec .navbar-nav .nav-link:hover {
        color: #000;
        font-size: 14px;
        /* margin: 25px 11px 0px 11px; */
    }

    .main-head-sec .navbar-nav .nav-link.active {
        color: #000;
        font-size: 14px;
        /* margin: 25px 11px 0px 11px; */
    }

    .main-head-sec .navbar-nav .nav-link.show {
        color: #000;
        font-size: 14px;
        /* margin: 25px 11px 0px 11px; */
    }

    .main-head-sec .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0px;
        padding-right: 0%;
    }

    .main-head-sec .userdiv .dropdown-menu {
        left: -140px !important;
    }
    .headsearchoffcanvas .offtitle {
        font-size: 20px !important;
    }
    .headsearchoffcanvas .searchdiv {
        margin: 20px 0px 55px 0px !important;
    }
    .headsearchoffcanvas .accordion-item .accordion-button {
        padding: 12px 0px !important;
        font-size: 14px !important;
    }
    .main-head-sec .navbar-nav .studiodrop .dropdown-menu {
        top: 43px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .main-head-sec .navbar-nav .nav-link {
        font-size: 13px;
    }
    .main-head-sec .userdiv {
        width: 35px;
        height: 35px;
        font-size: 20px;
    }

    .main-head-sec button.accordion-button {
        font-size: 13px;
    }

    .main-head-sec .navbar-nav .nav-link:hover {
        color: #000;
        font-size: 13px;
        /* margin: 25px 7px 0px 7px; */
    }

    .main-head-sec .navbar-nav .nav-link.active {
        color: #000;
        font-size: 13px;
        /* margin: 25px 7px 0px 7px; */
    }

    .main-head-sec .navbar-nav .nav-link.show {
        color: #000;
        font-size: 13px;
        /* margin: 25px 7px 0px 7px; */
    }



    .main-head-sec .login {
        margin-top: 14px;
        font-size: 13px;
    }

    .main-head-sec .treatname_text {
        font-size: 13px;
    }

    .main-head-sec .userdiv .dropdown-menu {
        left: -140px !important;
    }
    .main-head-sec .navbar-nav .studiodrop .dropdown-menu {
        top: 41px;
    }
}

@media (min-width: 0px) and (max-width: 991px) {

    .main-head-sec .deskviewserchacc {
        display: none;
    }
    
    .main-head-sec .mobviewserchacc {
        display: block;
        position: absolute;
        right: 40px;
        top: 20px;
    }

    .main-head-sec .userdiv {
        width: 30px;
        height: 40px;
        background: transparent;
        border-radius: 0%;
        font-size: 20px;
        margin: 0px 15px 0px 5px;
    }

    .main-head-sec .userdiv .dropdown-menu .dropdown-item {
        margin-bottom: 2px;
        font-size: 12px;
        padding: 7px;
    }
    .main-head-sec .userdiv .dropdown-menu {
        left: -103px !important;
        top: 39px;
        width: 150px;
    }

    .main-head-sec .navbar-toggler-icon {
        width: 2em;
        height: 1.5em;
        margin-left: 10px;
    }

    .main-head-sec .all-icon {
        margin-bottom: 0px;
       
    }

    .deskdroppp {
        display: none;
    }

    .mobdroppp {
        display: block;
    }

    .main-head-sec .treatname_text {
        font-size: 12px;
    }

    .main-head-sec .navbar-toggler {
        border: 0px;
    }

    /* .main-head-sec  .navbar-expand-lg .navbar-nav .dropdown-menu {
      left: 0;
    } */

    .main-head-sec .droprows1 {
        padding: 15px 0px;
    }

    .main-head-sec .droprows2 {
        padding: 18px 20px;
    }

    .main-head-sec .desktop-view {
        display: none;
    }



    .main-head-sec .login {
        font-size: 13px;
        color: #000;
        padding-top: 0px;
        margin-top: 2px;
    }

    .main-head-sec .headlogo {
        height: 45px;
    }

    .main-head-sec .mobile-view {
        display: block;
    }

    /* .main-head-sec  .navbar-toggler {
        color: #000;
        background-color: #fff !important;
        margin-top: 8px;
        padding: 2px 10px;
      } */



    .main-head-sec .navbar-nav .nav-link {
        font-size: 13px;
        margin: 0px 16px 0px 10px;
    }

    .main-head-sec button.accordion-button {
        font-size: 13px;
    }

    /* button.accordion-button.collapsed {
      font-size: 15px;
  } */

    .main-head-sec .navbar-nav .nav-link:hover {
        color: #000;
        font-size: 13px;
        margin: 0px 16px 0px 10px;
    }

    .main-head-sec .navbar-nav .nav-link.active {
        color: #000;
        font-size: 13px;
        border-bottom: none;
        margin: 0px 16px 0px 10px;
    }

    .main-head-sec .navbar-nav .nav-link.show {
        color: #000;
        font-size: 13px;
        border-bottom: none;
        margin: 0px 16px 0px 10px;
    }

    /* .main-head-sec  .navbar-nav .dropdown {
        width: 298px;
      } */
    /* .main-head-sec  .dropdown-toggle::after {
        position: absolute;
        right: inherit;
        margin-top: 6px;
      } */

    /* .main-head-sec  .dropdown-menu {
        width: 298px;
      } */



    .top-header .search .form-control {
        font-size: 13px;
    }

    .top-header .search .search-btn {
        font-size: 13px;
        width: 84px;
    }

    .top-header .headlogo {

        height: 40px;
    }

    .top-header {
        background-color: #fff;
        padding: 10px 0px;
    }

    .top-header .search {
        margin-top: 15px;
    }

    .main-head-sec .input-group-box {
        position: inherit;
        width: 100%;
        left: 0px;
        top: 8px;
    }
    .headsearchoffcanvas .offcanvas-header {
        padding-top: 10px !important;
    }
    .headsearchoffcanvas .offtitle {
        font-size: 15px !important;
    }
    .main-head-sec .navbar-nav .studiodrop .dropdown-menu {
        width: 250px;
    }
    .main-head-sec .navbar-nav .studiodrop .dropdown-menu .dropdown-item .menunamediv p {
        font-size: 12px;
    }
    .main-head-sec .navbar-nav .studiodrop .dropdown-menu .dropdown-item .menuimg {
        min-width: 50px;
        height: 35px;
    }
    .headsearchoffcanvas {
        top: 66px !important;
        margin-top: -19px !important;
    }
    .headsearchoffcanvas .accordion-item .accordion-body {
        font-size: 12px !important;
    }
    .headsearchoffcanvas .accordion-item .accordion-button:not(.collapsed) {
        font-size: 14px !important;
    }
    .headsearchoffcanvas .viewallbtn, .headsearchoffcanvas .viewallbtn:hover {
        font-size: 12px !important;
    }
    .Why_fns_sec .slider-sec .swiper-button-prev:after {
        left: -15px;
    }

    .headsearchoffcanvas .searchdiv {
        margin: 0px 0px 20px 0px !important;
    }

    .headsearchoffcanvas .accordion-item:last-of-type .accordion-button.collapsed {
        font-size: 14px !important;
    }
    .main-head-sec .navbar-nav .nav-link.active {
        width: fit-content !important;
    }
}


.offcanvas-backdrop {
    background-color: transparent !important;
}

.headsearchoffcanvas {
    top: 85px !important;
    background-color: #f8f8f8 !important;
    --bs-offcanvas-height: 70vh !important;
    border-bottom: 0px !important;
}

.headsearchoffcanvas .offtitle {
    font-weight: bold;
    font-size: 22px;
    color: #202020;
}

.headsearchoffcanvas .offcanvas-header {
    padding-top: 50px;
}

.headsearchoffcanvas .searchdiv .form-control:focus {
    box-shadow: none !important;
}

.headsearchoffcanvas .searchdiv {
    margin: 30px 0px 70px 0px;
}

.headsearchoffcanvas .searchdiv .form-control {
    border: 0px;
    border-radius: 0px;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
    font-size: 14px;
    color: #808080;
}

.headsearchoffcanvas .howcanp {
    font-size: 16px;
    color: #202020;
    margin: 20px 0px 15px 0px;
}

.headsearchoffcanvas .viewallbtn,
.headsearchoffcanvas .viewallbtn:hover {
    color: #000;
    font-weight: 500;
    font-size: 15px;
    background-color: #FFBE5C;
}

.headsearchoffcanvas .accordion {
    --bs-accordion-bg: transparent !important;
}

.headsearchoffcanvas .accordion-item {
    background-color: transparent !important;
    border: 0px;
}

.headsearchoffcanvas .accordion-item .accordion-button {
    padding: 15px 0px;
}

.headsearchoffcanvas .accordion-item .accordion-button:focus {
    box-shadow: none !important;
}

.headsearchoffcanvas .accordion-item .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent;
    box-shadow: none;
}

.headsearchoffcanvas .accordion-item .accordion-body {
    padding: 0px 0px 20px 0px;
    font-size: 14px;
}


@media (min-height: 420px) and (max-height: 700px) {
    .headsearchoffcanvas {
        --bs-offcanvas-height: 100vh !important;
    }
}