
.media-card-sec .nav-pills .nav-link {
    background: #333 0 0 no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    color: #707070;
    font-size: 15px;
    font-weight: 500;
    margin-right: 0px;
    padding: 8px 13px;
    transition: background .3s ease-in-out,border .3s ease-in-out;
    width: 90px;
    text-align: center;
    position: relative;
    overflow: hidden;
}
.media-card-sec .nav-pills .nav-link:hover{
    color: #000000;
}

.media-card-sec .nav-pills .nav-link:hover:before{
    opacity: 1; 
    transform: translate(0,0);
}
.media-card-sec .nav-pills .nav-link:before{
    content: attr(data-hover);
    position: absolute;
    top: 8px; 
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translate(-100%,0);
    transition: all .3s ease-in-out;
}
.media-card-sec .nav-pills .nav-link:hover span{
    opacity: 0; 
    transform: translate(100%,0)
}

.media-card-sec .nav-pills .nav-link span{
    transition: all .3s ease-in-out;
}

.media-card-sec .nav-pills .nav-link.active {
    background: #FFBE5C 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #202020;
    font-size: 15px;
    font-weight: 500;
    padding: 8px 20px;
}

.media-card-sec .nav-pills .nav-link:hover {
    background: #FFBE5C ;
    border-radius: 5px;
    color: #202020;
    padding: 8px 20px;
}

/* .media-card-sec .nav-pills .nav-link:hover span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
    transform: translateX(5px);
}

.media-card-sec .nav-pills .nav-link.active span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0px);
} */

.media-card-sec a {
    text-decoration: none;
}

.slider-icon .slide-fa {
    color: #BEBEBE;
}

.media-card-sec .pagination {
    background-color: transparent !important;
    --bs-pagination-bg: transparent !important;
}
.media-card-sec  .page-item:first-child .page-link {
    border-top-left-radius: 50px;
    border-bottom-left-radius:50px ;
}
.media-card-sec .pagination a:hover {
    background: #1A1A1A 0% 0% no-repeat padding-box;
    border: 2px solid #FFBE5C;
    color: #fff;
    box-shadow: none;
    border-radius: 50px;
}

.media-card-sec .pagination .active a {
    background: #1A1A1A 0% 0% no-repeat padding-box;
    border: 2px solid #FFBE5C;
    color: #fff;
    box-shadow: none;
    border-radius: 50px;
}

.media-card-sec .pagination a:focus {
    background: #1A1A1A 0% 0% no-repeat padding-box;
    border: 2px solid #FFBE5C;
    color: #fff;
    box-shadow: none;
}

.media-card-sec .pagination .active a {
    color: #ffffff;
    cursor: default;
    box-shadow: none;
}

.media-card-sec .page-item .page-link {
    background: #1A1A1A 0% 0% no-repeat padding-box;
    border: 2px solid #000000;
    border-radius: 50px;
    margin-right: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-card-sec .pagination .page-item .page-link-prev,
.pagination .page-link-next {
    transition: transform 0.3s ease;
}

.media-card-sec .pagination .page-item:hover .page-link-prev {
    transform: translateX(-5px);
}

.media-card-sec .pagination .page-item:hover .page-link-next {
    transform: translateX(5px);
}

.media-card-sec .page-item:not(:first-child) .page-link {
    border-width: 0.5px;
    border-radius: 50px;
}

.media-card-sec .page-link:active {
    background: #1A1A1A 0% 0% no-repeat padding-box;
    border: 2px solid #000000;
    border-radius: 50px;
    background: #d2e3ff;
}
.media-card-sec .background-card .spacing {
    /* padding: 0% 5%; */
    position: relative;
    z-index: 99;
}
.media-card-sec .nav-item{
    margin-left: 15px;
}
.media-card-sec .med-top{
    padding: 3% 2% 5% 2% !important;
}


@media (min-width:992px) and (max-width:1200px){
    .media-card-sec .background-card .spacing {
        /* padding: 0 15%; */
    }
}