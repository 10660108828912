.StudioTabs_section .nav {
    border-bottom: 1px solid #fff;
    padding-left: 20px;
}

.StudioTabs_section .nav-pills .nav-link {
    border-radius: 5px 5px 0px 0px;
    background: linear-gradient(rgba(112, 112, 112, 0.19) 0%, #000 100%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    color: #ddd;
    margin: 0px 5px;
    padding: 8px 50px;
}

.StudioTabs_section .nav-pills .nav-link.active {
    background: linear-gradient(0deg, #000, #838383);
}

.StudioTabs_section .tab-content .tab-cont {
    padding: 30px;
}

.StudioTabs_section .tab-content .tab-cont .ttiill {
    font-size: 20px;
    color: #bebebe;
    margin-bottom: 50px;
}

.StudioTabs_section .tab-content .tab-cont .ttiill span {
    font-size: 14px;
}

.StudioTabs_section .tab-content .tab-cont .ttiill span svg {
    color: #FFBE5C;
    font-size: 14px;
}

.StudioTabs_section .tab-content .tab-cont ol li {
    font-size: 16px;
    color: #bebebe;
    margin-bottom: 10px;
}
.StudioTabs_section .tab-content .tab-cont .rating-div{
    margin-bottom: 50px;
}
.StudioTabs_section .tab-content .tab-cont .rating-div .ttiill {
    font-size: 20px;
    color: #bebebe;
    margin-bottom: 12px;
}

.StudioTabs_section .tab-content .tab-cont .rating-div .review {
    font-size: 15px;
    color: #e5e5e5;
    margin-bottom: 0px;
}
.StudioTabs_section .tab-content .tab-cont .swiper-div .image-div{
    position: relative;
    width: 100%;
    height: 100%;
}
.StudioTabs_section .tab-content .tab-cont .swiper-div .image-div .project-img{
    width: 100%;
    height: 100%;
    /* position: relative; */
    /* z-index: 999; */
    border-radius: 25px;
}
.StudioTabs_section .tab-content .tab-cont .swiper-div .image-div .overlay-color{
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 40%;
    background: linear-gradient(0deg, #000, transparent);
    transition: 0.4s;
}
.StudioTabs_section .tab-content .tab-cont .swiper-div .image-div:hover .overlay-color{
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 60%;
    background: linear-gradient(0deg, #000, transparent);
    transition: 0.4s;
}
.StudioTabs_section .tab-content .tab-cont .swiper-div .image-div .overlay-content {
    position: absolute;
    bottom: 10px;
    left: 20px;
}


.StudioTabs_section .tab-content .tab-cont .swiper-div .image-div .overlay-content .name{
    margin-bottom: 0px;
    font-weight: normal;
    font-size: 14px;
    color: #e5e5e5;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
}
                                            
.StudioTabs_section .tab-content .tab-cont .swiper-div .image-div .overlay-content .date{
    margin-bottom: 0px;
    font-weight: normal;
    font-size: 12px;
    color: #e5e5e5;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
}
.StudioTabs_section .tab-content .tab-cont .project-swiper .swiper-wrapper{
    padding-bottom: 30px;
}



.StudioTabs_section .tab-content .tab-cont .project-swiper  .swiper-pagination-bullet-active {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}

.StudioTabs_section .tab-content .tab-cont .project-swiper  .swiper-pagination-bullet-active-next {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}

.StudioTabs_section .tab-content .tab-cont .project-swiper  .swiper-pagination-bullet-active-next-next {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}

.StudioTabs_section .tab-content .tab-cont .project-swiper  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.80);
}

.StudioTabs_section .tab-content .tab-cont .project-swiper  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.63);
}



.StudioTabs_section .tab-content .tab-cont .project-swiper  .swiper-pagination-bullet-active-prev-prev {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}

.StudioTabs_section .tab-content .tab-cont .project-swiper  .swiper-pagination-bullet-active-prev {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}


@media (min-width:0px) and (max-width:991px){
    .StudioTabs_section .nav-pills .nav-link {
        padding: 5px 15px;
        font-size: 14px;
        text-wrap: nowrap;
    }
    .StudioTabs_section .nav {
        border-bottom: 1px solid #fff;
        padding-left: 0px;
        display: inline-flex;
        max-width:100%;
        overflow-y: scroll;
        flex-flow: nowrap;
    }
    .StudioTabs_section .tab-content .tab-cont .ttiill {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .StudioTabs_section .tab-content .tab-cont ol li {
        font-size: 13px;
    }
    .StudioTabs_section .tab-content .tab-cont {
        padding: 10px;
    }
    .StudioTabs_section .tab-content .tab-cont .swiper-div .image-div .overlay-content .name {
        font-size: 12px;
    }
    .StudioTabs_section .tab-content .tab-cont .swiper-div .image-div .overlay-content .date {
        font-size: 10px;
    }
    .StudioTabs_section .tab-content .tab-cont .swiper-div .image-div .overlay-color {
        width: 100%;
        height: 100%;
    }
    .StudioTabs_section .tab-content .tab-cont .rating-div .ttiill {
        font-size: 15px;
    }
    .StudioTabs_section .tab-content .tab-cont .rating-div .review {
        font-size: 12px;
    }
}

@media (min-width:992px) and (max-width:1200px){
    .StudioTabs_section .tab-content .tab-cont .ttiill {
        font-size: 18px;
        margin-bottom: 40px;
    }
    .StudioTabs_section .tab-content .tab-cont ol li {
        font-size: 14px;
    }
    .StudioTabs_section .tab-content .tab-cont .rating-div .review {
        font-size: 14px;
    }
    .StudioTabs_section .nav-pills .nav-link {
        padding: 8px 30px;
    }
}

@media (min-width:1201px) and (max-width:1400px){
    .StudioTabs_section .tab-content .tab-cont .ttiill {
        font-size: 19px;
        margin-bottom: 40px;
    }
    .StudioTabs_section .tab-content .tab-cont ol li {
        font-size: 15px;
    }
    .StudioTabs_section .tab-content .tab-cont .rating-div .review {
        font-size: 14px;
    }
}