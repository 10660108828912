.Breadcrumb_comp {
    padding: 4% 0% 3% 0%;
    position: relative;
    z-index: 99;
}
.Breadcrumb_comp .breadcrumb-item a{
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.Breadcrumb_comp  .breadcrumb-item+.breadcrumb-item::before {
    content: none;
}
.Breadcrumb_comp  .breadcrumb-item.active {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    margin: auto 0px;
}
.Breadcrumb_comp .firstbread{
    font-weight: 300;
}

@media (min-width:0px) and (max-width:991px){
    .Breadcrumb_comp .breadcrumb-item a {
        font-size: 11px;
    }    
    .Breadcrumb_comp  .breadcrumb-item.active {
        font-size: 11px;
        margin-top: 7px;
    }

}