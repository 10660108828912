.Our_mission_sec .background {
    background: #1A1A1A;
    padding: 5%;
    border-radius: 0px 0px 50px 50px;
}
.Our_mission_sec .pronounced-sec{
    margin-top: 5%;
}
.Our_mission_sec .pronounced-sec .title {
    font-weight: bold;
    font-size: 40px;
    color: #bebebe;
}

.Our_mission_sec .mision-title {
    font-weight: bold;
    font-size: 40px;
    color: #bebebe;
}

.Our_mission_sec .mission-main .text {
    font-size: 16px;
    color: #bebebe;
    margin-bottom: 0px;
}

.mission-main .icon-div .icon {
    min-width: 30px;
    height: 30px;
}

.Our_mission_sec .mission-main .title {
    font-weight: 500;
    font-size: 30px;
    color: #bebebe;
}

.Our_mission_sec .mission-main .content-div {
    margin-left: 30px;
}

.Our_mission_sec .mission-main .feedback-div .per {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 2px solid #FFBE5C;
    margin: 0px 0px 0px -7px;
}

.Our_mission_sec .mission-main .feedback-div .count {
    font-weight: 600;
    font-size: 25px;
    color: #bebebe;
    margin: 5px 0px 0px -10px;
}

.Our_mission_sec .mission-main .feedback-div .posi-fed {
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.1em;
    color: #bebebe;
    margin: -4px 0px 0px -10px;
}


@media (min-width:0px) and (max-width:767.98px){
    .Our_mission_sec .mision-title {
        font-size: 22px;
    }
    .Our_mission_sec .pronounced-sec .title {
        font-size: 22px;
    }
    .Our_mission_sec .mission-main .text {
        font-size: 13px;
    }
    .Our_mission_sec .mission-main .title {
        font-size: 18px;
    }
    .mission-main .icon-div .icon {
        min-width: 20px;
        height: 20px;
    }
    .Our_mission_sec .mission-main .content-div {
        margin-left: 15px;
    }

    .Our_mission_sec .mission-main  .feedback-div .d-flex{
        justify-content: center !important;
    }
    .Our_mission_sec .mission-main .feedback-div .count {
        font-size: 18px;
        text-align: center;
    }
    .Our_mission_sec .mission-main .feedback-div .posi-fed {
        font-size: 13px;
        text-align: center;
        margin: 0px 0px 0px 0px;
    }
    .Our_mission_sec .pronounced-sec {
        margin-top: 0%;
    }
}

@media (min-width:768px) and (max-width:1200px){
    .Our_mission_sec .mision-title {
        font-size: 30px;
    }
    .Our_mission_sec .mission-main .text {
        font-size: 14px;
    }
    .Our_mission_sec .mission-main .title {
        font-size: 20px;
    }
    .Our_mission_sec .mission-main .content-div {
        margin-left: 20px;
    }
    .mission-main .icon-div .icon {
        min-width: 25px;
        height: 25px;
    }
    .Our_mission_sec .mission-main .feedback-div .count {
        font-size: 20px;
    }
    .Our_mission_sec .mission-main .feedback-div .posi-fed {
        font-size: 14px;
    }
    .Our_mission_sec .pronounced-sec .title {
        font-size: 30px;
    }
}

@media (min-width:1201px) and (max-width:1400px){
    .Our_mission_sec .mision-title {
        font-size: 35px;
    }
    .Our_mission_sec .pronounced-sec .title {
        font-size: 35px;
    }
    .Our_mission_sec .mission-main .title {
        font-size: 27px;
    }
    .Our_mission_sec .mission-main .text {
        font-size: 15px;
    }
    .mission-main .icon-div .icon {
        min-width: 27px;
        height: 27px;
    }
}