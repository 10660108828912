.About_content .cont-div .Title {
    font-weight: bold;
    font-size: 42px;
    color: #bebebe;
    margin-bottom: 0px;
}

.About_content .subtitle {
font-weight: bold;
font-size: 14px;
color: #bebebe;

}
.About_content  .content{
    font-size: 15px;
    color: #bebebe;    
    margin-bottom: 20px;
}
.About_content  .abtus{
font-weight: 900;
font-size: 197px;
color: #000;
position: relative;
width: max-content;
}

.About_content  .abtus .Uset {
    transform: scale(1, 1.9);
    display: inline-block;
    position: relative;
    top: 100px;
}
.About_content  .abtus .s-set {
    position: absolute;
    right: -35px;
    bottom: -140px;
}
.About_content  .abt-box-shd {
    box-shadow: 0px 3px 15px 0px #00000061;
    border-radius:25px 25px 0px 0px !important;
}

.About_content .abt-logo-div .log {
    width: 100px;
    filter: saturate(0);
}

.About_content .abt-logo-div {
    width: 120px;
    height: 120px;
    margin: auto;
    bottom: -65px;
    left: 44%;
    background: #1a1a1a;
    position: absolute;
    border-radius: 100px;
    text-align: center;
    box-shadow: 0px 3px 3px 0px #000000b3;
}
.About_content .about-text-div .text-img{
    width: 100%;
}


@media (min-width:1201px) and (max-width:1400px){
    .About_content  .abtus {
        font-size: 165px;
    }
    .About_content .cont-div .Title {
        font-size: 40px;
    }
}


@media (min-width:992px) and (max-width:1200px){
    .About_content  .abtus {
        font-size: 150px;
    }
    .About_content .cont-div .Title {
        font-size: 37px;
    }
    .About_content  .content {
        font-size: 14px;
    }
}

@media (min-width:768px) and (max-width:991px){
    .About_content  .abtus {
        font-size: 120px;
    }
    .About_content .cont-div .Title {
        font-size: 35px;
    }
    .About_content  .content {
        font-size: 14px;
    }
}
@media (min-width:0px) and (max-width:991px){
    .About_content  .abtus .Uset {
        transform: unset;
        display: inline-block;
        position: relative;
        top: 0px;
    }
    .About_content  .abtus .s-set {
        position: unset;
    }
    .About_content  .abtus {
        font-size: 50px;
    }
    .About_content .cont-div .Title {
        font-size: 25px;
    }
    .About_content  .content {
        font-size: 13px;
        text-align: justify;
    }
    .About_content  .abt-box-shd {
        padding: 8% 2% 15% 2% !important;
    } 
    .About_content .abt-logo-div .log {
        width: 50px;
    }
    .About_content .abt-logo-div {
        width: 70px;
        height: 70px;
        margin: auto;
        bottom: -30px;
        left: 44%;
    }
}   


@media (min-width:0px) and (max-width:767.98px){
    .About_content .about-text-div .text-img{
        width: 60%;
    }
    .About_content .about-text-div{
        text-align: center;
        margin-bottom: 20px;
    }
}