.Studio_big_slider_modal .slider-main .slider-img{
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 100px 0px #fff;
    border-radius: 10px;
}
.Studio_big_slider_modal .slider-main{
    border-radius: 10px;
}

.Studio_big_slider_modal .modal-content {
    background-color: transparent;
    border: 0px;
}
.Studio_big_slider_modal .modal-header {
    border: 0px;
}
.Studio_big_slider_modal .swiper-wrapper {
    /* padding: 50px 10px; */
}

.Studio_big_slider_modal{
    --bs-modal-width: 100% I !important;
}

.Studio_big_slider_modal .swiper-slide-active {
    z-index: 999;
    transform: scale(1.8);
}

.Studio_big_slider_modal .swiper {
    /* padding: 5% 0%; */
    overflow: visible;
}
.Studio_big_slider_modal .swiper-slide-next{
    transform: scale(1.2);
}
.Studio_big_slider_modal .swiper-slide-prev{
    transform: scale(1.2);
}


.Studio_big_slider_modal   .silder-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
}

.Studio_big_slider_modal   .silder-btn .next-btn {
    content: url("../../../../../public/assets/images/studio-inner/right.png");
    width: 30px;
    height: 30px;
    /* margin: 10px; */
    cursor: pointer;
    position: absolute;
    bottom: 45%;
    z-index: 999;
    right: 20%;
    filter: hue-rotate(45deg);
    filter: brightness(1);
}
.Studio_big_slider_modal   .silder-btn .back-btn {
    content: url("../../../../../public/assets/images/studio-inner/left.png");
    width: 30px;
    height: 30px;
    /* margin: 10px; */
    cursor: pointer;
    position: absolute;
    bottom: 45%;
    z-index: 999;
    left:20%;
    filter: hue-rotate(45deg);
    filter: brightness(1);
}

@media (min-width:0px) and (max-width:575px){
    .Studio_big_slider_modal .swiper-slide-active {
        transform: scale(2.5);
    }


    .Studio_big_slider_modal   .silder-btn .next-btn {                     
        width: 25px;
        height: 25px;
        bottom: 28%;
        right: 11%;
    }

    .Studio_big_slider_modal   .silder-btn .back-btn {                     
        width: 25px;
        height: 25px;
        bottom: 28%;
        left: 11%;
    }
}