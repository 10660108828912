.Home_inquiry_sec {
    padding: 3% 0%;
    box-shadow: 0px 1px 20px 20px transparent;
    backdrop-filter: blur(0.1px);
    /* background: linear-gradient(90deg, #1e071f, #062825); */
    position: relative;
    z-index: 999;
    /* filter: blur(50px); */
}

.Home_inquiry_sec .overlay-bg {
    background: linear-gradient(90deg, #48164a, #084641);
    filter: blur(50px);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}

.Home_inquiry_sec .openyuor {
    font-family: 'Averia Serif Libre', 'sans-serif' !important;
    font-weight: 900;
    color: #fff;
    font-size: 35px;
    margin-bottom: 0px;
    position: relative;
}

.Home_inquiry_sec .enquiry-btn,
.Home_inquiry_sec .enquiry-btn:hover {
    border-radius: 5px;
    background: #ffbe5c;
    padding: 7px 50px;
    font-size: 15px;
    text-align: left;
    color: #202020;
    position: relative;
}

@media (min-width:0px) and (max-width:991px){
    .Home_inquiry_sec .openyuor {
        font-size: 25px;
        text-align: center;
    }
    .Home_inquiry_sec .enquiry-btn, .Home_inquiry_sec .enquiry-btn:hover {
        padding: 7px 30px;
        font-size: 14px;
        margin-top: 20px;
    }
}
