.Studio_spaces_sec {
    padding: 5% 0%;
}

.Studio_spaces_sec .title-div .title {
    font-family: 'Averia Serif Libre', 'sans-serif' !important;
    font-weight: 800;
    font-size: 35px;
    color: #fff;
}

.Studio_spaces_sec .ban-div {
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}

.Studio_spaces_sec .ban-div:hover .std-img {
    transform: scale(1.1);
    transition: 0.3s;
}

.Studio_spaces_sec .std-img {
    width: 100%;
    height: auto;
    transition: 0.3s;
}

.Studio_spaces_sec .ban-div .overlay-text {
    position: absolute;
    bottom: 10px;
    left: 25px;
    text-align: left;
}

.Studio_spaces_sec .ban-div .overlay-text .first-text {
    font-weight: bold;
    font-size: 25px;
    color: #fff;
    margin-bottom: 0px;
}


@media (min-width:0px) and (max-width:991px) {
    .Studio_spaces_sec .ban-div .overlay-text .first-text {
        font-size: 15px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
    }
    
    .Studio_spaces_sec .ban-div .overlay-text {
        bottom: 5px;
        left: 10px;
    }
}