.booking-cancel-modal .modal-body{
    padding: 30px !important;
}
.booking-cancel-modal .modal-body .title{
    font-weight: bold;
    font-size: 23px;
    color: #000;
    margin-bottom: 40px;
}
.booking-cancel-modal .modal-body .resaop-pp{
    font-size: 16px;
    color: #000;
}
.booking-cancel-modal .modal-body .form-check {
    margin-bottom: 5px;
}
.booking-cancel-modal .modal-body .form-check .form-check-label{
    font-size: 14px;
    color: #000;
    
}
.booking-cancel-modal .modal-body .form-check-input {
    border: 2px solid #000;
}
.booking-cancel-modal .modal-body .form-check-input:checked {
    background-color: #000000 !important;
    border-color: #000000 !important;
}

.booking-cancel-modal .modal-body  .form-floating>.form-control {
    /* padding: 0px; */
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #000;
}  
.booking-cancel-modal .modal-body  .form-floating>label {
    font-size: 14px;
}
.booking-cancel-modal .modal-body .submit-btn{
    padding: 8px 25px;
    color: #000;
    font-size: 14px;
    background-color: #FFBE5C;
    border-radius: 5px;
    border: 0px;
    font-weight: 600;
    margin: 5px;
}
.booking-cancel-modal .modal-body .cancel-btn{
    padding: 8px 25px;
    color: #000;
    font-size: 14px;
    background-color: #E8E8E8;
    border-radius: 5px;
    border: 0px;
    font-weight: 600;
    margin: 5px;
}