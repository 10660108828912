.Contact_section {
    background: linear-gradient(180deg, #202020, #000000);
    padding: 5% 0% 0% 0%;
    width: 100%;
    height: 950px;
}



.Contact_section .form-sec .conttitle {
    font-size: 100px;
    color: #ffffff36;
    font-weight: 600;
}

.Contact_section .form-sec .form-label {
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
}

.Contact_section .form-sec .form-control {
    background-color: transparent;
    padding: 5px 10px;
    font-size: 14px;
    border: 0px;
    border-bottom: 1px solid #000;
    border-radius: 0px;
}

.Contact_section .form-sec .form-group {
    margin-bottom: 15px;
}

.Contact_section .form-sec .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #000;
}

.Contact_section .form-sec .react-tel-input .form-control {
    width: 100%;
    padding-left: 40px;
}

.Contact_section .form-sec .submit-btn {
    background-color: #FFBE5C;
    padding: 8px 25px;
    font-size: 14px;
    border-radius: 5px;
    border: 0px;
    margin-top: 15px;
}

.Contact_section .form-sec .small-map-img {
    position: absolute;
    right: 8%;
    width: 170px;
    border: 10px solid #060606;
    border-radius: 20px;
}

.Contact_section .img-sec .banner-img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.gmap_iframe {
    width: 100% !important;
    height: 50vh !important;
}




.flip-card {
    background-color: transparent;
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 1000px;
    z-index: 999;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    /* text-align: center; */
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flipped .flip-card-inner {
    transform: rotateX(180deg) !important;
}

/* .flip-card:hover .flip-card-inner {
    transform: rotateX(180deg);
  } */

.flip-card-front,
.flip-card-back {
    /* position: absolute;
    width: 100%;
    height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background: #fff8f8;
    border-radius: 10px;
    padding: 40px 70px;
}

.flip-card-back {
    transform: rotateX(180deg);
}


/* .flip-card-back {
    display: none;
}
.flipped  .flip-card-back{
    display: block;
}
.flipped  .flip-card-front{
    display: none;
} */


/* .flipped  .flip-card-inner{
    display: none;
}
.flip-card-inner{
    display: block;
} */




.Contact_section .contmainpos{
    position: relative;
}
.Contact_section .contmainpos .img-sec {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 45%;
}
.map-section{
    position: relative;    
}
.map-section .close-map-icon{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    background-color: #fff;
    border-radius: 100px;
    z-index: 999;
}






@media (min-width:0px) and (max-width:767.98px) {
    .Contact_section .form-sec .conttitle {
        font-size: 40px;
        text-align: center;
    }
    .Contact_section .contmainpos .img-sec {
        display: none;
    }
    .Contact_section .form-sec .small-map-img {
        right: 2%;
        width: 85px;
        margin-top: 50px;
    }
    .flip-card-front {
        padding: 40px 25px;
    }
    .Contact_section {
        height: 600px;
    }
    
.gmap_iframe {
    width: 100% !important;
    height: 420px !important;
}
}
@media (min-width:768px) and (max-width:991px) {
    .Contact_section .form-sec .conttitle {
          font-size: 50px;
      }
      .Contact_section {
        height: 700px;
    }
  }

@media (min-width:992px) and (max-width:1200px) {
  .Contact_section .form-sec .conttitle {
        font-size: 60px;
    }
    .Contact_section {
        height: 750px;
    }
}

@media (min-width:1201px) and (max-width:1400px) {
    .Contact_section .form-sec .conttitle {
        font-size: 80px;
    }

    .Contact_section {
        height: 835px;
    }
}