.main .Successfull_Lottie_icon {
  width: 75px;
  height: 75px;
  margin: auto;
}






@media (min-width: 0px) and (max-width: 991px) {
  .main .Successfull_Lottie_icon {
    width: 70px;
    height: 70px;
}
}


