@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Genty Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Gilroy:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Itim:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto', 'sans-serif' !important;
  background-color: #000 !important;
  overflow-x: hidden;
}

.form-control:focus {
  box-shadow: none !important;
}

.background-card {
  background: #1A1A1A 0% 0% no-repeat padding-box;
  padding: 8% 2% 5% 2%;
  border-radius: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  /* border-radius: 45% 45% 0% 0% / 15% 15% 50% 50%; */
}
.background-card .curve-img {
    position: absolute;
    width: 100%;
    top: -61px;
    left: 0px;
}



@media (min-width:0px) and (max-width:991px) {
  .background-card {
    border-radius: 5px;
  }
  .background-card .curve-img {
    display: none;
  }
}

@media (min-width:992px) and (max-width:1200px) {
  .background-card .curve-img {
    top: -44px;
}
}

/* .layer-img{
  position: absolute;
  top: 0px;
  width: 100%;
} */
/* element.style {
    width: 100%;
    height: 300px;
    background: linear-gradient(#e3f1ff, #fff);
    border-radius: 50% 50% 0% 0% /   50% 50%;
} */

/* .main .modal{
  backdrop-filter: brightness(2);
} */


.About_banner_sec {
  position: relative;
  width: 100%;
  height: 100%;
}

.About_banner_sec .banner-img {
  width: 100%;
}

.About_banner_sec .overlayblack {
  position: absolute;
  bottom: 0px;
  background: linear-gradient(0deg, black, transparent);
  width: 100%;
  height: 100%;
}

.About_banner_sec .overlay-text {
  position: absolute;
  top: 0px;
  text-align: center;
  width: 100%;
  top: 40%;
}

.About_banner_sec .overlay-text .banner-title {
  font-weight: bold;
  font-size: 48px;
  color: #fff;
}

.About_banner_sec .overlay-text .subtitle {
  font-family: 'Averia Serif Libre', 'sans-serif' !important;
  font-weight: 600;
  font-size: 22px;
  color: #ff5757;
}


@media (min-width:0px) and (max-width:767.98px) {
  .About_banner_sec .overlay-text .banner-title {
    font-size: 22px;
    margin-bottom: 0px;
  }

  .About_banner_sec .overlay-text .subtitle {
    font-size: 12px;
  }
}

@media (min-width:768px) and (max-width:1200px) {
  .About_banner_sec .overlay-text .banner-title {
    font-size: 35px;
  }

  .About_banner_sec .overlay-text .subtitle {
    font-size: 18px;
  }
}

@media (min-width:1201px) and (max-width:1400px) {
  .About_banner_sec .overlay-text .banner-title {
    font-size: 40px;
  }

  .About_banner_sec .overlay-text .subtitle {
    font-size: 20px;
  }
}