.Historycard_view_modal .modal-body{
    padding: 20px 50px;
}
.Historycard_view_modal .modal-body .cont-div h6{
    font-size: 14px;
    margin-bottom: 0px;
}

.Historycard_view_modal .modal-body .cont-div p{
    font-size: 13px;
    margin-bottom: 0px;
}
.Historycard_view_modal .modal-body .icondiv {
    margin-right: 15px;
}
.Historycard_view_modal .modal-body .icondiv svg{
    font-size: 15px;
    color: #000;
}