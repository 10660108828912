.Counter_sec .counter-bg-div {
    background: linear-gradient(270deg, #15f2c0 0%, #2f9eca 28.08%, #8f00c9 49.07%, #8726af 72.3%, #db198c 100%);
    padding: 1% 3%;
    border-radius: 5px;
}

.Counter_sec .counter-bg-div .count-text {
    color: #fff;
    font-size: 80px;
    font-weight: 800;
    margin-bottom: 0px;
}

.Counter_sec .counter-bg-div .spank {
    font-size: 50px;
    color: #fff;
    font-weight: 800;
}

.Counter_sec .counter-bg-div .subtext {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0px;
    margin-top: -8px;
}
.Counter_sec .counter-bg-div .aligndiv{
    display: flex;
    justify-content: center;
}
.Counter_sec .counter-bg-div .aligndiv h1{
    margin-bottom: 0px;
}
@media (min-width:0px) and (max-width:991px){
    .Counter_sec .counter-bg-div .count-text {
        font-size: 30px;
    }
    .Counter_sec .counter-bg-div .subtext {
        font-size: 13px;
    }
    .Counter_sec .counter-bg-div .spank {
        font-size: 22px;
    }
}

@media (min-width:992px) and (max-width:1200px){
    .Counter_sec .counter-bg-div .count-text {
        font-size: 60px;
    }
    .Counter_sec .counter-bg-div .subtext {
        font-size: 15px;
    }
    .Counter_sec .counter-bg-div .spank {
        font-size: 30px;
    }
}