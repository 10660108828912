.Why_fns_sec {
    padding: 6% 0%;
}

.Why_fns_sec .fns-img {
    width: 100%;
    height: auto;
}

.Why_fns_sec .slider-sec .title {
    font-weight: 900;
    font-size: 120px;
    color: #cbcbcb;
    margin: -25px 0px 0px 0px;
}

.Why_fns_sec .newand {
    /* font-family: "Genty Sans"; */
    font-family: 'Averia Serif Libre', 'sans-serif' !important;
    /* font-family: 'Itim', 'sans-serif' !important; */
    font-weight: 600;
    font-size: 35px;
    color: #fff;
    margin-top: 40px;
}

.Why_fns_sec .slider-text {
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    margin-bottom: 0px;
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
}

.Why_fns_sec .slider-sec .swiper-button-prev {
    position: absolute;

}

.Why_fns_sec .slider-sec .swiper-wrapper {
    padding-bottom: 50px;
}

.Why_fns_sec .slider-sec .swiper-button-next {
    right: auto !important;
    left: 35px !important;
}

.Why_fns_sec .slider-sec .swiper-button-prev {
    right: auto !important;
    left: 0px !important;
}

.Why_fns_sec .slider-sec .swiper-button-prev:after {
    content: "";
    background: url('../../../../public/assets/images/homepage/why-fns/next.png');
    padding: 10px;
    background-size: 17px;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    margin: 5px;
}


.Why_fns_sec .slider-sec .swiper-button-next:after {
    content: "";
    background: url('../../../../public/assets/images/homepage/why-fns/next.png');
    padding: 10px;
    background-size: 17px;
    background-repeat: no-repeat;
}

.Why_fns_sec .slider-sec .swiper-button-prev:after,
.Why_fns_sec .slider-sec .swiper-button-next:after {
    bottom: -40px;
    position: absolute;
}

.Why_fns_sec .slider-sec .swiper-button-prev:after,
.Why_fns_sec .slider-sec .swiper-button-next:after {
    font-size: 20px;
    color: #fff;
}

.Why_fns_sec .slider-sec .swiper-pagination-fraction {
    color: #fff;
    bottom: 0px;
    left: 70px;
    text-align: left;
}

.Why_fns_sec .slider-sec .swiper-main {
    margin-top: 50px;
}

.Why_fns_sec .this-make-sec {
    margin-top: 8%;
}

.Why_fns_sec .this-make-sec .special-card {
    position: relative;
    margin: auto;
    overflow: hidden;
    border-radius: 10px;
}

.Why_fns_sec .this-make-sec .special-card .bg-img {
    width: 100%;
}

.Why_fns_sec .this-make-sec .special-card .overlay-content {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 20px;
}

.Why_fns_sec .this-make-sec .special-card .overlay-content .ttt {
    font-weight: 500;
    font-size: 30px;
    color: #fff;
}

.Why_fns_sec .this-make-sec .special-card .overlay-content .subcont {
    font-weight: normal;
    font-size: 15px;
    color: #fff;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
}

.Why_fns_sec .this-make-sec .special-card .overlay-content .number-div {
    position: relative;
}

.Why_fns_sec .this-make-sec .special-card .overlay-content .number-div .number {
    font-weight: normal;
    font-size: 150px;
    color: #ffbe5c;
    position: absolute;
    left: -45px;
    top: 0px;
    transition: all 0.4s ease-in-out;
}

.Why_fns_sec .this-make-sec .special-card:hover .overlay-content .number-div .number {
    top: -35px;
    transition: all 0.4s ease-in-out;
}

@media (min-width:0px) and (max-width:991px) {
    .Why_fns_sec .slider-sec .swiper-button-prev {
        left: 14px !important;
    }
}
@media (min-width:0px) and (max-width:767.98px) {
    .Why_fns_sec .this-make-sec .special-card .overlay-content .ttt {
        font-size: 22px;
    }

    .Why_fns_sec .this-make-sec .special-card .overlay-content .number-div .number {
        font-size: 70px;
        top: 30px;
        left: -32px;
    }

    .Why_fns_sec .this-make-sec .special-card:hover .overlay-content .number-div .number {
        top: 23px;
    }

    .Why_fns_sec .this-make-sec .special-card .overlay-content .subcont {
        font-size: 13px;
    }

    .Why_fns_sec .slider-sec .title {
        font-size: 40px;
        margin: 0px 0px 0px 0px;
        text-align: center;
    }

    .Why_fns_sec .newand {
        font-size: 20px;
        margin-top: 20px;
        text-align: center;
    }

    .Why_fns_sec .slider-sec .swiper-main {
        margin-top: 20px;
        text-align: center;
    }

    .Why_fns_sec .slider-sec .swiper-pagination-fraction {
        bottom: 0px;
        left: 60%;
    }

    .Why_fns_sec .slider-sec .swiper-button-next {
        left: 50% !important;
    }

    .Why_fns_sec .slider-sec .swiper-button-prev {
        left: 45% !important;
    }

    .Why_fns_sec .slider-text {
        font-size: 15px;
    }

    .Why_fns_sec .slider-sec .swiper-wrapper {
        padding-bottom: 60px;
    }
}

@media (min-width:768px) and (max-width:1200px) {
    .Why_fns_sec .this-make-sec .special-card .overlay-content .ttt {
        font-size: 25px;
    }

    .Why_fns_sec .this-make-sec .special-card .overlay-content .number-div .number {
        font-size: 100px;
        top: 20px;
        left: -34px;
    }

    .Why_fns_sec .this-make-sec .special-card:hover .overlay-content .number-div .number {
        top: 4px;
    }

    .Why_fns_sec .slider-sec .title {
        font-size: 70px;
        margin: -10px 0px 0px 0px;
    }

    .Why_fns_sec .newand {
        font-size: 25px;
        margin-top: 10px;
    }

    .Why_fns_sec .slider-sec .swiper-main {
        margin-top: 25px;
    }
}



@media (min-width:1201px) and (max-width:1400px) {
    .Why_fns_sec .this-make-sec .special-card .overlay-content .ttt {
        font-size: 28px;
    }

    .Why_fns_sec .this-make-sec .special-card .overlay-content .number-div .number {
        font-size: 140px;
        top: -30px;
    }

    .Why_fns_sec .slider-sec .title {
        font-size: 100px;
    }

    .Why_fns_sec .slider-sec .swiper-main {
        margin-top: 12px;
    }
}