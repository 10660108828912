.Book_studio_modal {
    border-radius: 20px;
}
.Book_studio_modal .modal-body {
    padding: 4% 7%;
}
.Book_studio_modal .modal-body .mod-img{
    width: 100%;
}
.Book_studio_modal .modal-body .stuh5{
    font-weight: bold;
    font-size: 25px;
    color: #202020;
    margin-bottom: 25px;
}
.Book_studio_modal .modal-body .pick-p{
    font-weight: bold;
    font-size: 20px;
    color: #202020;    
}
.Book_studio_modal .modal-body .mod-btn{
font-weight: 500;
font-size: 15px;
color: #202020;
border: 0px;
border-radius: 5px;
background: #ffbe5c;
width: 184px;
padding: 7px;
text-align: center;
margin: 0px 5px;
}

.modal-backdrop {
    -webkit-backdrop-filter: blur(5px) !important;
    backdrop-filter: blur(13px) !important;
    background-color: #3b3b3ba1 !important;
    --bs-backdrop-opacity: 1 !important;
}

 .modal-backdrop.in{
    opacity: 1 !important;
 }

 @media (min-width:0px) and (max-width:991px){
    .Book_studio_modal .modal-body .stuh5 {
        font-size: 20px;
    }
    .Book_studio_modal .modal-body .pick-p {
        font-size: 15px;
    }
    .Book_studio_modal .modal-body .mod-btn {
        font-size: 14px;
        width: 100px;
    }
 }