.login-page-main-sec {
    background: linear-gradient(180deg, rgb(0 0 0 / 45%) 0%, #00000066 100%), url("../../../public/assets/images/login/bg-2.png");
    background-color: #fff;
    opacity: 1;
    width: 100%;
    background-size: cover;
    height: 100vh;
    overflow: auto;
    /* filter: brightness(1.5); */
}

.login-page-main-sec .main-position-div {
    position: relative;
    top: 80px;
    width: 100%;
}

.main-position-div .aligndiv {
    justify-content: space-between;
}

.main-position-div .d-flex .first-div {
    width: 35%;
}



.main-position-div .d-flex .last-div {
    width: 35%;
}

.mid-img-div {}


.slidein {
    position: absolute;
    right: 65%;
    /* transform: translateY(-50%);  */
    animation-name: slidein;
    animation-duration: 0.3s;
    /* Animation duration */
    animation-timing-function: linear;
    /* Optional: Linear timing */
}

.slidein2 {
    position: absolute;
    right: 0%;
    /* transform: translateY(-50%);  */
    animation-name: slidein2;
    animation-duration: 0.3s;
    /* Animation duration */
    animation-timing-function: linear;
    /* Optional: Linear timing */
}

@keyframes slidein2 {
    from {
        left: 0%;
        /* Start position, left edge */
    }

    to {
        left: 100%;
        /* End position, right edge */
    }
}

@keyframes slidein {
    from {
        right: 0%;
    }

    to {
        right: 65%;
    }
}

/* @keyframes slidein2 {
    from {
        left: 0%;
    }
    to {
        left: 100%;
    }
} */




.login-page-main-sec .main-position-div .loginformsec {
    background-color: #FFF8F8;
    padding: 24px 45px;
    border-radius: 20px;
    position: relative;
}

.login-page-main-sec .main-position-div .home-icn {
    font-size: 12px;
    color: #000;
}

.login-page-main-sec .main-position-div .login-title {
    font-weight: 800;
    font-size: 22px;
    color: #131313;
    margin-top: 30px;
    margin-bottom: 0px;
}
.login-page-main-sec .main-position-div .title-sub {
    font-weight: 800;
    font-size: 14px;
    color: #131313;
}
.login-page-main-sec .main-position-div .form-group  .form-label{
    font-weight: normal;
    font-size: 15px;
    color: #000;
    margin-bottom: 0px;
}
.login-page-main-sec .main-position-div .form-group .form-control{
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #000;
    font-size: 14px;
    width: 100%;
}

.login-page-main-sec .main-position-div  .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #000;
}

.login-page-main-sec .main-position-div  .react-tel-input .form-control {
    width: 100%;
    padding-left: 40px;
}




.login-page-main-sec .main-position-div .form-group .form-control::placeholder{
    font-size: 14px;
}

.login-page-main-sec .main-position-div .form-group {
    margin-bottom: 20px;
    position: relative;
}
.login-page-main-sec .main-position-div .form-group .eye-icc {
    position: absolute;
    bottom: 8px;
    right: 0px;
}
.login-page-main-sec .main-position-div .form-group .forgo-pass{
    font-size: 13px;
    color: #000;
    text-decoration: none;
}   
.login-page-main-sec .main-position-div .Login-btn{
    background-color: #FFBE5C;
    border: 0px;
    border-radius: 5px;
    padding: 8px 30px;
    font-size: 14px;
}
.login-page-main-sec .main-position-div .lastcont{
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-top: 20px;
}
.login-page-main-sec .main-position-div .lastcont a {
    color: #000;
    font-style: italic;
    margin-right: 5px;
    font-weight: 600;
}
.login-page-main-sec .main-position-div .loginby{
    font-size: 13px;
    color: #000;
    font-weight: 500;
    margin-bottom: 0px;
}
.login-page-main-sec .main-position-div .socialicon{
    width: 18px;
    height: 18px;
    margin-left: 10px;
}

.login-page-main-sec .main-position-div .form-check-inline label{
    font-size: 12px;
}
.login-page-main-sec .main-position-div .form-check-input:checked {
    background-color: #6c757d;
    border-color: #6c757d;
}
.main-position-div .d-flex .mid-img-div {
    width: 37%;
    position: absolute;
    left: 30%;
}
.main-position-div .d-flex .mid-img-div img{
    width: 100%;
    border-radius: 10px;
}
.main-position-div .d-flex .margin-top{
    margin-top: 5%;
}

.main-position-div .d-flex .fadediv{
    opacity: 0.5;
    cursor: not-allowed;
  pointer-events: all !important;
    
}
.resetpassformtop {
    margin: 85px 0px !important;
}
.resetpasstop{

}

.main-position-div .loginformsec .welcometitle h1{
    font-family: 'Averia Serif Libre', 'sans-serif' !important;
    font-weight: 700;
    font-size: 25px;
    color: #6642c966;
    position: absolute;
    right: 10px;
    top: 10px;
}   
.main-position-div .topspace {
    margin-top: 7%;
}


@media (min-width:0px) and (max-width:991px){
    .main-position-div .d-flex .mid-img-div {
        display: none;
    }
    .main-position-div .d-flex .fadediv {
       display: none;
    }
    .main-position-div .d-flex .last-div {
        width: 100%;
        right: 1%;
    }
    .main-position-div .d-flex .margin-top {
        margin-top: 0%;
    }
    
    .login-page-main-sec .main-position-div .login-title {
        font-size: 18px;
    }
    .login-page-main-sec .main-position-div .title-sub {
        font-size: 13px;
    }
    .login-page-main-sec .main-position-div .form-group .forgo-pass {
        font-size: 12px;
    }
    .login-page-main-sec .main-position-div .Login-btn {
        font-size: 12px;
    }
    .login-page-main-sec .main-position-div .lastcont {
        font-size: 12px;
    }
    .login-page-main-sec .main-position-div .loginby {
        font-size: 12px;
    }
    .slidein {
        animation-name: none;
    }
    .slidein2 {
        animation-name: none;
    }
    .login-page-main-sec .main-position-div .form-group  .form-label {
        font-size: 13px;
    }
}

@media (min-width:992px) and (max-width:1201px){
    .main-position-div .d-flex .mid-img-div {
        width: 53%;
        left: 14%;
    }
    .main-position-div .topspace {
        margin-top: 18%;
    }
}

@media (min-width:1201px) and (max-width:1400px){
    .main-position-div .d-flex .mid-img-div {
        width: 43%;
        left: 24%;
    }
    .main-position-div .d-flex .margin-top {
        margin-top: 4%;
    }
    .main-position-div .topspace {
        margin-top: 13%;
    }
}



@media (min-width:2000px) and (max-width:3000px){
    .login-page-main-sec .main-position-div {
        top: 260px;
    }
}