.Studio_slider {
    padding: 3% 0%;
}

.Studio_slider .swiper-main {
    background: linear-gradient(90deg, #000, #262626, #000);
    padding: 20px 0px;
}

.Studio_slider .swiper-main .swiper-div {
    border: 1px solid #fff;
    clip-path: polygon(0% 0%, 85% 0, 100% 15%, 100% 100%, -1% 102%);
    border-radius: 10px;
    aspect-ratio: 1.3/1;
    object-fit: cover;
    position: relative;
    background: #fff;
}

.Studio_slider .swiper-main .swiper-div .stud-img {
    width: 100%;
    height: 100%;
    /* border: 1px solid #fff; */
    /* border-radius: 45% 45% 0% 0% / 15% 15% 50% 50%; */
    /* clip-path: polygon(0 0, 85% 0, 100% 15%, 100% 100%, 1% 100%); */
    clip-path: polygon(0% 0%, 85% 0, 100% 15%, 100% 100%, -1% 102%);
}


.Studio_slider   .silder-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
}

.Studio_slider   .silder-btn .next-btn {
    content: url("./../../../../public/assets/images/studio-inner/right.png");
    width: 30px;
    height: 30px;
    /* margin: 10px; */
    cursor: pointer;
    position: absolute;
    bottom: 42%;
    z-index: 999;
    right: 0%;
    filter: hue-rotate(45deg);
    filter: brightness(1);
}
.Studio_slider   .silder-btn .back-btn {
    content: url("./../../../../public/assets/images/studio-inner/left.png");
    width: 30px;
    height: 30px;
    /* margin: 10px; */
    cursor: pointer;
    position: absolute;
    bottom: 42%;
    z-index: 999;
    left:0%;
    filter: hue-rotate(45deg);
    filter: brightness(1);
}

.Studio_slider  .swiper {
    position: relative;
}
.Studio_slider  .swiper::after {
    content: "";
    position: absolute;
    right: 0px;
    height: 100%;
    width: 88px;
    background: linear-gradient(270deg, black , transparent);
    z-index:99;
    top: 0px;
}
.Studio_slider  .swiper::before {
    content: "";
    position: absolute;
    left: 0px;
    height: 100%;
    width: 88px;
    background: linear-gradient(90deg, black , transparent);
    z-index:999 ;
    top: 0px;
}

@media (min-width:0px) and (max-width:991px){
    .Studio_slider .swiper-main .swiper-div {
        border-radius: 3px;
    }
}
