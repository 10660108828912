.home-motivated {
    margin: 5% 0%;
}

.home-motivated .title {
    font-family: 'Averia Serif Libre', 'sans-serif' !important;
    font-size: 35px;
    color: #fff;
    font-weight: 600;
}

.home-motivated .slider-section .swiper-wrapper {
    padding: 50px;
    height: 450px;
}

.home-motivated .slider-section .imgSlider_sec {
    position: relative;
}

.home-motivated .slider-section .swiper-slide-active .imgSlider_sec .img-bg1 {
    width: 100%;
    aspect-ratio: 1/1.3;
    object-fit: cover;
    object-position: top;
    border-radius: 7px;
    height: 100%;
    background-color: #FFBE5C;
    position: relative;
    top: 0px;
    left: 0px;
    transform: rotate(-5deg);
}

.home-motivated .slider-section .swiper-slide-active .imgSlider_sec .emp1 {
    width: 100%;
    aspect-ratio: 1/1.3;
    object-fit: cover;
    object-position: top;
    border-radius: 7px;
    position: absolute;
    z-index: 99;
    transform: rotate(10deg);
}

.home-motivated .slider-section .swiper-slide-next .imgSlider_sec {
    margin-top: 20%;
}

.home-motivated .slider-section .swiper-slide-next .imgSlider_sec .emp1 {
    width: 60%;
    aspect-ratio: 1/1.3;
    object-fit: cover;
    border-radius: 8px;
    position: relative;
    z-index: 9;
    padding: 5px;
}

.home-motivated .slider-section .swiper-slide-next .imgSlider_sec .img-bg1 {
    position: absolute;
    top: 0%;
    left: 0%;
    background-color: #FFBE5C;
    width: 53%;
    height: 92%;
    border-radius: 8px;
}


.home-motivated .slider-section .swiper-slide-prev .imgSlider_sec .emp1 {
    width: 60%;
    aspect-ratio: 1/1.3;
    object-fit: cover;
    border-radius: 8px;
    position: relative;
    z-index: 9;
    padding: 5px;
}

.home-motivated .slider-section .swiper-slide-prev .imgSlider_sec .img-bg1 {
    position: absolute;
    top: 0%;
    left: 0%;
    background-color: #FFBE5C;
    width: 53%;
    height: 92%;
    border-radius: 8px;
}

.home-motivated .slider-section .swiper-slide-active .colhide {
    padding-left: 60px;
}

.symbol-img {
    width: 75px;
    height: 75px;
}

.home-motivated .slider-section .swiper-slide-active .testimoni-cont {
    font-weight: normal;
    font-size: 15px;
    color: #fff;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    overflow: hidden;
    margin-top: 80px;
}

.home-motivated .slider-section .swiper-slide-active .name {
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    margin: 40px 0px 0px 0px;
}

.home-motivated .slider-section .swiper-slide-active .location {
    font-weight: normal;
    font-size: 15px;
    color: #fff;
    margin-bottom: 0px;
}

/* .home-motivated .slider-section .silder-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    left: 36%;
    top: 15%;
} */

.home-motivated .slider-section .silder-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    top: 0;
    left: 36%;
    top: 425px;
}

/* .home-motivated .slider-section .silder-btn .back-btn {
    cursor: pointer;
    position: absolute;
    bottom: 185px;
    z-index: 999;
    left: 0px;
    color: #C01D97;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.home-motivated .slider-section .silder-btn .back-btn {
    cursor: pointer;
    position: absolute;
    /* bottom: 185px; */
    z-index: 999;
    left: 0px;
    color: #C01D97;
    /* background: #fff; */
    width: 22px;
    height: 22px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-motivated .slider-section .silder-btn .back-btn:hover {
    color: #fff;
    background: #C01D97;
}

/* .home-motivated .slider-section .silder-btn .next-btn {
    cursor: pointer;
    position: absolute;
    bottom: 185px;
    z-index: 999;
    left: 20px;
    color: #C01D97;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.home-motivated .slider-section .silder-btn .next-btn {
    cursor: pointer;
    position: absolute;
    /* bottom: 185px; */
    z-index: 999;
    left: 20px;
    color: #C01D97;
    /* background: #fff; */
    width: 22px;
    height: 22px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-motivated .slider-section .silder-btn .next-btn:hover {
    color: #fff;
    background: #C01D97;
}

/* @media (min-width:0px) and (max-width:575px) {
    .home-motivated .slider-section .swiper-wrapper {
        height: 200px;
    }
    .home-motivated .slider-section .swiper-slide-active .colhide {
        padding-left: 20px;
    }    
}
@media (min-width:320px) and (max-width:400px) {
    .home-motivated .slider-section .silder-btn {
        left: 38%;
        top: 210px;
    }
}
@media (min-width:0px) and (max-width:319px) {
    .home-motivated .slider-section .silder-btn {
        left: 40%;
        top: 210px;
    }
}
@media (min-width:400px) and (max-width:575px) {
    .home-motivated .slider-section .silder-btn {
        left: 38%;
        top: 210px;
    }
}

@media (min-width:576px) and (max-width:767.98px) {
    .home-motivated .slider-section .swiper-wrapper {
        height: 270px;
    }
    .home-motivated .slider-section .swiper-slide-active .colhide {
        padding-left: 35px;
    }
    .home-motivated .slider-section .silder-btn {
        left: 35%;
        top: 210px;
    }
} */


@media (min-width:0px) and (max-width:767.98px) {
    .home-motivated .title {
        font-size: 20px;
        text-align: center;
    }


    .home-motivated .slider-section .swiper-wrapper {
        padding: 20px 0px;
    }

    .home-motivated .slider-section .swiper-slide-active .colhide {
        padding-left: 0px;
    }
 
    .symbol-img {
        width: 25px;
        height: 25px;
    }
    .home-motivated .slider-section .swiper-slide-active .testimoni-cont {
        font-size: 11px;
        margin-top: 10px;
        text-align: justify;
    }
    
    .home-motivated .slider-section .swiper-slide-active .name {
        font-size: 13px;
        margin: 0px 0px 0px 0px;
        /* text-align: center; */
    }
    .home-motivated .slider-section .swiper-slide-active .location {
        font-size: 10px;
        /* text-align: center; */
    }
    .home-motivated .slider-section .swiper-slide-next .imgSlider_sec {
        margin-top: 0%;
        text-align: center;
    }
    .home-motivated .slider-section .silder-btn {
        position: absolute !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        /* height: 100%; */
        top: 0;
        left: 43%;
        top: 90%;
    }  
}

@media (min-width:0px) and (max-width:349px) {
    .home-motivated .slider-section .swiper-wrapper {
        height: 380px;
    }
}
@media (min-width:350px) and (max-width:400px) {
    .home-motivated .slider-section .swiper-wrapper {
        height: 420px;
    }
}
@media (min-width:401px) and (max-width:499px) {
    .home-motivated .slider-section .swiper-wrapper {
        height: 450px;
    }
}
@media (min-width:500px) and (max-width:599px) {
    .home-motivated .slider-section .swiper-wrapper {
        height: 550px;
    }
}

@media (min-width:600px) and (max-width:767.98px) {
    .home-motivated .slider-section .swiper-wrapper {
        height: 625px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .home-motivated .title {
        font-size: 25px;
    }

    .home-motivated .slider-section .swiper-wrapper {
        height: 200px;
    }
    .symbol-img {
        width: 35px;
        height: 35px;
    }
    .home-motivated .slider-section .swiper-slide-active .colhide {
        padding-left: 30px;
    }
    .home-motivated .slider-section .swiper-slide-active .testimoni-cont {
        font-size: 13px;
        margin-top: 25px;
    }
    .home-motivated .slider-section .swiper-slide-active .name {
        font-size: 15px;
        margin: 0px 0px 0px 0px;
    }
    .home-motivated .slider-section .swiper-slide-active .location {
        font-size: 12px;
    }
    /* .home-motivated .slider-section .silder-btn {
        left: 38%;
        top: -2%;
    } */

    .home-motivated .slider-section .silder-btn {
        left: 38%;
        top: 270px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .symbol-img {
        width: 60px;
        height: 60px;
    }

    .home-motivated .slider-section .swiper-wrapper {
        height: 300px;
    }

    .home-motivated .slider-section .swiper-slide-active .testimoni-cont {
        font-size: 14px;
        margin-top: 20px;
    }

    .home-motivated .slider-section .swiper-slide-active .name {
        font-size: 16px;
        margin: 30px 0px 0px 0px;
    }

    .home-motivated .slider-section .swiper-slide-active .location {
        font-size: 12px;
    }
    /* .home-motivated .slider-section .silder-btn {
        left: 38%;
        top: 15%;
    } */

    .home-motivated .slider-section .silder-btn {
        left: 38%;
        top: 320px;
    }
}

@media (min-width:1201px) and (max-width:1400px) {
    /* .home-motivated .slider-section .silder-btn {
        left: 37%;
        top: 20%;
    } */

    .home-motivated .slider-section .swiper-wrapper {
        height: 350px;
    }

    .home-motivated .slider-section .silder-btn {
        left: 37%;
        top: 380px;
    }

    .home-motivated .slider-section .swiper-slide-active .testimoni-cont {
        margin-top: 50px;
    }

    .home-motivated .slider-section .swiper-slide-active .name {
        margin: 30px 0px 0px 0px;
    }
}