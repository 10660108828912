.Meet_team_section .meetteambg {
    background: #1A1A1A 0% 0% no-repeat padding-box;
    padding: 8% 2% 5% 0%;
}

.Meet_team_section .meetteambg .title-div {
    text-align: center;
}

.Meet_team_section .meetteambg .title-div .subt {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.5em;
    color: #bebebe;
}

.Meet_team_section .meetteambg .team-title {
    font-weight: 900;
    font-size: 40px;
    color: #bebebe;
}

.Meet_team_section .meetteambg .subt2 {
    font-weight: 300;
    font-size: 13px;
    letter-spacing: 0.25em;
    color: #ffbe5c;
}

.Meet_team_section .meetteambg .bonds-title {
    font-weight: 900;
    font-size: 150px;
    color: #000;
}

.Meet_team_section .meetteambg .bonds-card-main .bonds-title {
    font-weight: 900;
    font-size: 150px;
    color: #000;
    transition: 0.4s;
}

.Meet_team_section .meetteambg .bonds-card-main:hover .bonds-title {
    font-weight: 900;
    font-size: 150px;
    color: #5b5b5b;
    letter-spacing: 40px;
    transition: 0.4s;
}
.Meet_team_section .meetteambg .bonds-card-main .space1{
    padding: 0px;   
    transition: 0.4s;
}

.Meet_team_section .meetteambg .bonds-card-main .space2{
    margin-top: 40px;
    transition: 0.4s;
}



.Meet_team_section .meetteambg .bonds-card-main .space2 .bd1::before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 75px;
    height: 2px;
    background: #fff;
    transition: 0.3s;
}
.Meet_team_section .meetteambg .bonds-card-main .space2 .bd1::after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right:  0px;
    width: 2px;
    height: 150px;
    background: #fff;
    transition: 0.3s;
}

.Meet_team_section .meetteambg .bonds-card-main:hover .space2 .bd1::before {
    width: 0px;
    height: 0px;
    transition: 0.3s;
}
.Meet_team_section .meetteambg .bonds-card-main:hover .space2 .bd1::after {
    width: 0px;
    height: 0px;
    transition: 0.3s;
}










.Meet_team_section .meetteambg .bonds-card-main .space2 .bd2::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 75px;
    height: 2px;
    background: #fff;
}
.Meet_team_section .meetteambg .bonds-card-main .space2 .bd2::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left:  0px;
    width: 2px;
    height: 150px;
    background: #fff;
}


.Meet_team_section .meetteambg .bonds-card-main:hover .space2 .bd2::before {
    width: 0px;
    height: 0px;
    transition: 0.3s;
}
.Meet_team_section .meetteambg .bonds-card-main:hover .space2 .bd2::after {
    width: 0px;
    height: 0px;
    transition: 0.3s;
}




.Meet_team_section .meetteambg .bonds-card-main:hover .space1{
    padding: 10px;   
    transition: 0.4s;
}

.Meet_team_section .meetteambg .bonds-card-main:hover .space2{
    margin-top: 0px;
    transition: 0.4s;
}






.Meet_team_section .meetteambg .bonds-card-main .teamcard{
    position: relative;
    width: 100%;
}
.Meet_team_section .meetteambg .bonds-card-main .teamcard .person{
    width: 100%;
}
.Meet_team_section .meetteambg .bonds-card-main .teamcard  .overlay-content{
    position: absolute;
    width: 100%;
    left: 15px;
    bottom: 15px;
    text-align: left;
}
.Meet_team_section .meetteambg .bonds-card-main .teamcard  .socialiccc{
    height: 12px;
    width: auto;
    margin: 0px 8px 0px 0px;
}
.Meet_team_section .meetteambg .bonds-card-main .teamcard .name{
    font-weight: 500;
    font-size: 20px;
    color: #bebebe;
    margin-bottom: 3px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
}

.Meet_team_section .meetteambg .bonds-card-main .teamcard .designation{
font-weight: normal;
font-size: 15px;
color: #bebebe;
margin-bottom: 0px;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
display: -webkit-box;
overflow: hidden;
}


.Meet_team_section .meetteambg .bonds-card-main .colapsediv{
    margin: -75px 0px 0px 0px;
}
.Meet_team_section .meetteambg .bonds-card-main  .teamcard .black-overlay {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 0%;
    background: linear-gradient(0deg , #000 , transparent);
    transition: 0.3s;
}
.Meet_team_section .meetteambg .bonds-card-main:hover  .teamcard .black-overlay {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 50%;
    background: linear-gradient(0deg , #000 , transparent);
    transition: 0.3s;
}


@media (min-width:0px) and (max-width:767.98px){
    .Meet_team_section .meetteambg .bonds-card-main .colapsediv {
        margin: 0px 0px 0px 0px;
    }
    .Meet_team_section .meetteambg .bonds-card-main .bonds-title , .Meet_team_section .meetteambg .bonds-card-main:hover .bonds-title {
        font-size: 40px;
        letter-spacing: 0px;
    }
    .Meet_team_section .meetteambg {
        padding: 30% 2% 5% 0%;
    }
    .About_content .abt-logo-div {
        left: 40%;
    }
 
    .Meet_team_section .meetteambg .bonds-card-main .space2 {
        margin-top: 0px;
    }

    .Meet_team_section .meetteambg .bonds-card-main .teamcard .name {
        font-size: 16px;
    }
    .Meet_team_section .meetteambg .bonds-card-main .teamcard .designation {
        font-size: 12px;
    }
}


@media (min-width:768px) and (max-width:991px){
    .Meet_team_section .meetteambg .bonds-card-main .bonds-title {
        font-size: 90px;
    }
    .Meet_team_section .meetteambg .bonds-card-main:hover .bonds-title {
        font-size: 110px;
    }
    .Meet_team_section .meetteambg .bonds-card-main .colapsediv {
        margin: -45px 0px 0px 0px;
    }

}


@media (min-width:992px) and (max-width:1200px){
    .Meet_team_section .meetteambg .bonds-card-main .bonds-title {
        font-size: 110px;
    }
    .Meet_team_section .meetteambg .bonds-card-main:hover .bonds-title {
        font-size: 130px;
    }
    .Meet_team_section .meetteambg .bonds-card-main .colapsediv {
        margin: -60px 0px 0px 0px;
    }
}

@media (min-width:1201px) and (max-width:1400px){
    .Meet_team_section .meetteambg .bonds-card-main .bonds-title {
        font-size: 130px;
    }    
    .Meet_team_section .meetteambg .bonds-card-main .colapsediv {
        margin: -60px 0px 0px 0px;
        transition: 0.3s;
    }
    .Meet_team_section .meetteambg .bonds-card-main:hover .colapsediv {
        margin: -75px 0px 0px 0px;
        transition: 0.3s;
    }
    .Meet_team_section .meetteambg .bonds-card-main:hover .bonds-title {
        font-size: 142px;
        letter-spacing: 38px;
    }
 
}