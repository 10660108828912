.Success_modal .Success_modal_holder {
  position: relative;
}

.Success_modal .Success_modal_holder .text-holder {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* margin-right: 35px; */
  width: 100%;
}

.Success_modal .Success_modal_holder .text-holder .main-text {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.Success_modal .Success_modal_holder .text-holder .small-text {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}
.Success_modal .Success_modal_holder .lottie-holder {
  margin: -55px 0px 10px 0px;
}
.Success_modal .Success_modal_holder .lottie-holder {
  /* position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  left: -35px;
  top: -45px; */
}
.Success_modal .Success_modal_holder  .first-div{
  width: 20% I !important;
}

@media (min-width: 0px) and (max-width: 991px) {



}
@media (min-width: 0px) and (max-width: 320px) {
  .Success_modal .Success_modal_holder .text-holder .main-text {
    font-size: 14px;
  }

}

@media (min-width: 321px) and (max-width: 485px) {
  .Success_modal .Success_modal_holder .text-holder .main-text {
    font-size: 16px;
  }

  
}

@media (min-width: 486px) and (max-width: 575px) {
  .Success_modal .Success_modal_holder .text-holder .main-text {
    font-size: 16px;
  }

}
