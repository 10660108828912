.faq .accordion-button:not(.collapsed) {
    color: #BEBEBE;
    background-color: transparent;
    box-shadow: none;
    font-size: 18px;
    font-weight: 500;
}

.faq .accordion-button {
    color: #BEBEBE;
    background-color: transparent;
    box-shadow: none;
    font-size: 18px;
    font-weight: 500;
}

.faq .accordion-body p {
    color: #BEBEBE;
    font-size: 15px;

}

.faq .accordion-button:focus {
    box-shadow: none;
}

.faq .accordion-item {
    color: #BEBEBE;
    background-color: transparent;
    border: none;
}

.faq .accordion-button:not(.collapsed)::after {
    background: url("../../../public/assets/images/icon/up-arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: none
}

.faq .accordion-button::after {
    background-image: url("../../../public/assets/images/icon/down-arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.faq .accordion-item .accordion-body{
    padding: 10px 0px 0px 60px;
}

@media(min-width:0px) and (max-width:767px) {
    .faq .accordion-button:not(.collapsed) {
        font-size: 15px;
    }

    .faq .accordion-button {
        font-size: 15px;

    }

    .faq .accordion-body p {
        font-size: 12px;

    }
}