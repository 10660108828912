.Enquiry_form_modal .modal-body {
    padding: 0px;
}

.Enquiry_form_modal .sec-1 {
    padding: 30px;
    background: #fff8f8;
    border-radius: 10px;
}

.Enquiry_form_modal .sec-2 {
    width: 100%;
    height: 100%;
}

.Enquiry_form_modal .modal-content {
    border-radius: 15px;
}

.Enquiry_form_modal .sec-2 .ban-bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right;
}

.Enquiry_form_modal .sec-1 .gomeicc {
    color: #000;
    font-size: 12px;
}

.Enquiry_form_modal .sec-1 .enqh6 {
    font-weight: bold;
    font-size: 22px;
    color: #131313;
    margin: 10px 0px 15px 0px;
}

.Enquiry_form_modal .sec-1 .form-label {
    font-size: 15px;
    color: #000;
    margin-bottom: 0px;
}

.Enquiry_form_modal .sec-1 .form-control {
    font-size: 14px;
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid #000;
    padding: 4px 0px;
    background-color: transparent;
}

.Enquiry_form_modal .sec-1 .react-tel-input .form-control {
    padding: 4px 40px;
    width: 100%;
}

.Enquiry_form_modal .sec-1 .form-group {
    margin-bottom: 10px;
}

.Enquiry_form_modal .sec-1 .react-tel-input .flag-dropdown {
    background-color: transparent;
    border: 0px solid #cacaca;
    border-bottom: 1px solid #000;
    border-radius: 0px 0 0 3px;
}

.Enquiry_form_modal .sec-1 .submit-btn {
    background: #ffbe5c;
    padding: 8px 30px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    border: 0px;
    border-radius: 5px;
    margin: 30% 0% 10% 0%;
}


@media (min-width:0px) and (max-width:767.98px) {
    .Enquiry_form_modal .sec-1 {
        padding: 15px;
    }

    .Enquiry_form_modal .sec-1 .submit-btn {
        font-size: 13px;
        margin: 15% 0% 5% 0%;
    }

    .Enquiry_form_modal .sec-1 .form-label {
        font-size: 14px;
    }

    .Enquiry_form_modal .sec-1 .form-control {
        font-size: 12px;
    }

    .Enquiry_form_modal .sec-1 .enqh6 {
        font-size: 20px;
    }
}