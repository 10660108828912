.Hourly_bookings_topbg {
    background: linear-gradient(0deg, black, #494949);
}
.Hourly_bookings_topbg{
    position: relative;
}
.make-yourh1 {
    position: absolute;
    color: #4d4d4d;
    top: 11%;
    font-weight: 700;
    font-size: 110px;
}

.booking-from-sec {
    position: relative;
    margin-top: 4%;
    width: 100%;
    height: 100%;
}

.booking-from-sec .form-bg {
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 10px;
}

.booking-from-sec .form-bg .form-group .react-datepicker-wrapper {
    width: 100%;
}

.booking-from-sec .form-bg .form-group .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 0px;
}

.booking-from-sec .form-bg .form-group .form-control {
    border: 0px;
    border-bottom: 1px solid #000;
    border-radius: 0px;
}

.booking-from-sec .estimatedh6 {
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    margin-bottom: 0px;
    padding: 40px;
}

.booking-from-sec .form-bg .form-group {
    margin-bottom: 10px;
}

.booking-from-sec .form-bg .form-group .form-label {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 15px;
    color: #000;
}

.booking-from-sec .form-bg .form-group .form-control::placeholder {
    font-size: 12px;
}

.booking-from-sec .form-bg .form-group .form-control {
    font-size: 14px;
    width: 100%;
}

.booking-from-sec .form-bg .form-group .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #fff;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #000;
}

.booking-from-sec .form-bg .accordion-item {
    border: 0px;
}

.booking-from-sec .form-bg .accordion-button {
    font-size: 18px;
}

.booking-from-sec .form-bg .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    color: #000;
}

.booking-from-sec .form-bg .accordion-button:focus {
    box-shadow: none;
}

.booking-from-sec .form-bg .accordion-button {
    padding: 0px;
    font-weight: 700;
}

.booking-from-sec .form-bg .accordion-button span {
    font-weight: 400;
    font-size: 13px;
}

.booking-from-sec .proceed-btn {
    background: #FFBE5C;
    padding: 10px 20px;
    border-radius: 5px;
    border: 0px;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
}

.booking-from-sec .form-bg .accordion-body {
    padding: 20px 0px;
}

.booking-from-sec .form-bg .accordion-body .addh6 {
    font-size: 15px;
    font-weight: 600;
}

.booking-from-sec .form-bg .accordion-body .acc-opn .form-check .form-check-label {
    font-size: 13px;
    color: #000;
}
.react-datepicker__input-container .form-control {
    margin-top: 5px;
}

@media (min-width:0px) and (max-width:991px) {
    .booking-from-sec .estimatedh6 {
        font-size: 15px;
        padding: 20px;
    }
    .booking-from-sec .form-bg .form-group .form-label {
        font-size: 14px;
    }
    .booking-from-sec .form-bg .accordion-button {
        font-size: 14px;
    }
    .booking-from-sec .form-bg .accordion-body .addh6 {
        font-size: 13px;
    }
    .booking-from-sec .form-bg .accordion-body .acc-opn .form-check .form-check-label {
        font-size: 11px;
    }
    .booking-from-sec .proceed-btn {
        font-size: 13px;
    }
}

@media (min-width:0px) and (max-width:400px) {
    .make-yourh1 {
        top: 5%;
        font-size: 22px;
        left: 3%;
    }
}

@media (min-width:401px) and (max-width:575px) {
    .make-yourh1 {
        top: 6%;
        font-size: 30px;
        left: 3%;
    }
}

@media (min-width:576px) and (max-width:767.98px) {
    .make-yourh1 {
        top: 7%;
        font-size: 45px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .make-yourh1 {
        top: 8%;
        font-weight: 700;
        font-size: 60px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .make-yourh1 {
        font-size: 77px;
        top: 10%;
    }
}

@media (min-width:1201px) and (max-width:1400px) {
    .make-yourh1 {
        font-size: 95px;
    }
    .make-yourh1 {
        top: 10%;
    }
}