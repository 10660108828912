.footer_div {
    background: #000;
    margin: 30px 0px 0px 0px;
    position: relative;
    z-index: 999;
}

.footer_div .footer_top {
    border-top: 1px solid #FFBE5C;
    padding: 50px 20px 20px 20px;
}
.footer_div .top_text {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0px;
    margin-bottom: 10px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
}

.footer_div .contact_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer_div .headericn_div {
    height: 27px;
    width: 27px;
    margin-left: 10px;
    background: #fff 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #000;
}
.footer_div .cart_logo {
    color: #000;
    font-size: 13px;
}
.footer_div .all_icon {
    display: flex;
    align-items: center;
    padding-top: 10px;
    justify-content: center;
}
.footer_div .footer_miidle {
    padding-top: 20px;
}

.footer_div .bottom_footer_text {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.12px;
    color: #fff;
    margin-bottom: 0px;
    padding-bottom: 15px;
    /* text-decoration: none; */
}

.footer_div .padding_div {
    padding-bottom: 40px;
}

.footer_div .footericn {
    color: #a6a6a6;
    padding-right: 10px;
}
.footer_div .footer-main {
    justify-content: space-between;
}
.footer_div .big-title{
    text-align: center;
}
.footer_div .big-title h1 {
    font-size: 230px;
    font-weight: 900;
    font-family: 'Averia Serif Libre', 'sans-serif' !important;
    color: #1c1c1c;
    margin-bottom: 0px;
    white-space: nowrap;
    height: 180px;
    overflow: hidden;
    text-overflow: "----";
    border: 1px solid #000000;
}
.footer_div .top_div_footer  a{
    text-decoration: none;
}


@media (min-width: 0px) and (max-width: 767.98px) {
    .footer_div .contact_div {
        flex-direction: column;
    }
    .footer_div .padding_div {
        padding-bottom: 15px;
    }
    .footer_div .footer_top {
        padding: 15px 5px 26px 5px;
    }
    .footer_div .top_text {
        font-size: 12px;
    }
    .footer_div .bottom_footer_text {
        font-size: 11px;
    }
}

@media (min-width: 768px) and (max-width: 1400px) {
    .footer_div .top_text {
        font-size: 13px;
    }
}

@media (min-width: 1201px) and (max-width: 1400px) {
    .footer_div .big-title h1 {
        font-size: 180px;
        height: 150px;
    }

}   


@media (min-width: 768px) and (max-width: 1200px) {
    .footer_div .big-title h1 {
        font-size: 120px;
        height: 100px;
    }
}   


@media (min-width: 576px) and (max-width: 767.98px) {
    .footer_div .big-title h1 {
        font-size: 80px;
        height: 70px;
    }
}

@media (min-width: 401px) and (max-width: 575px) {
    .footer_div .big-title h1 {
        font-size: 60px;
        height: 50px;
    }
}


@media (min-width: 0px) and (max-width: 400px) {
    .footer_div .big-title h1 {
        font-size: 40px;
        height: 35px;
    }
}


