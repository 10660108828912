.media-card {
    background: #646464 0% 0% no-repeat padding-box !important;
    border-radius: 10px !important;
    border: none !important;
    margin-top: 60px;

}

.media-card .image-holder .media-img {
    width: 100%;
    height: 100%;
    transition: 0.3s;
    border-radius: 10px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.media-card .image-holder {
    margin: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.media-card a {
    text-decoration: none;

}

.media-card:hover .image-holder .media-img {
    transform: scale(1.1);
    transition: 0.3s;
    border-radius: 10px;
}

.media-card:hover {
    background: #E2E2E2 0% 0% no-repeat padding-box !important;
    border-radius: 10px;
}



.media-card .card-title {
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
}

.media-card:hover .card-title {
    color: #000;
}

.media-card .location p {
    color: #FFF;
    font-size: 13px;
    margin-bottom: 0%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
}

.media-card:hover .location p {
    color: #000;
}

.media-card .details .list-search {
    width: 14px;
    height: 12px;
}

.media-card .details span {
    color: #FFF;
    font-size: 13px;
}

.media-card:hover .details span {
    color: #000;
}

.media-card .details .list-search {
    filter: brightness(0) invert(1);
}

.media-card:hover .details .list-search {
    filter: brightness(0%);
}
.media-card .rate-list svg{
    color: #FFBE5C ;
    font-size: 10px;
}
.media-card .rate-list p {
    color: #FFF;
    font-size: 13px;
}

.media-card:hover .rate-list p {
    color: #000;
    font-size: 13px;
}

.media-card .star-icon {
    cursor: pointer;

    transition: color 0.3s ease;
}

.media-card:hover .star-icon {
    color: #FF5757;
}

.media-card .star-icon.clicked {
    color: #FF5757;
}

.media-card .book-taxt {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 1;
    display: flex;
}

.media-card .book-taxt p {
    background-color: #FFF;
    padding: 2px 4px 0px 8px;
    opacity: 0.8;
    font-size: 11px;
    font-weight: bold;
    color: #000;
    border-radius: 6px 0px 0px 6px;
    margin-top: 6px;
    margin-right: -2px;

}

.media-card .circle-icon {
    background-color: #FFF;
    width: 32px;
    height: 32px;
    opacity: 0.8;
    border-radius: 50px;
    text-align: center;
    border: 1px solid #000;
}

.media-card .book-taxt .book-icon {
    color: #FF5757;
}
.media-card .card-body{
    padding: 0px 15px;
    margin-bottom: -5px;
}