.home-banner {
    margin-bottom: 5%;
    height: 1000px;
    position: relative;
    overflow: hidden;
}

.home-banner .sec-1 {
    width: 58%;
    position: relative;
}

.home-banner .sec-1 .img-div {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
}

.home-banner .sec-2 {
    position: absolute;
    width: 58%;
    right: 0%;
    top: 0%;
}

.home-banner .sec-1 .ban-img {
    width: 100%;
}

.home-banner .sec-1 .ban-img::after {
    top: 90px;
    left: -20px;
    transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -webkit-transform: rotate(5deg);
    content: "";
    position: absolute;
}

.home-banner .sec-2 .ban-img {
    width: 101%;
}

.home-banner .sec-1 .slider-div {
    position: absolute;
    left: 17%;
    width: 60%;
    z-index: 999;
    top: 300px;
}

.home-banner .sec-1 .slider-div .slidemain-t {
    font-family: system-ui;
    /* font-family: Gilroy; */
    font-weight: 300;
    font-size: 30px;
    color: #202020;
    position: relative;
    margin-bottom: 0px;
}

.home-banner .sec-1 .slider-div .slidemain-t::after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 0px;
    width: 190px;
    height: 2px;
    background-color: #FF5757;
}

.home-banner .sec-1 .slider-div .main-div {
    margin-top: 30px;
}

.home-banner .sec-1 .slider-div .main-div .title {
    /* font-family: Gilroy; */
    font-family: system-ui;
    font-weight: 700;
    font-size: 65px;
    color: #202020;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
}

.home-banner .sec-1 .slider-div .swiper-wrapper {
    padding-bottom: 50px;
}

.home-banner .sec-1 .slider-div .swiper-pagination {
    left: 30px !important;
    width: 100% !important;
}

.home-banner .sec-1 .slider-div .swiper-pagination-bullet-active {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}

.home-banner .sec-1 .slider-div .swiper-pagination-bullet-active-next {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}

.home-banner .sec-1 .slider-div .swiper-pagination-bullet-active-next-next {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}

.home-banner .sec-1 .slider-div .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.80);
}

.home-banner .sec-1 .slider-div .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.63);
}



.home-banner .sec-1 .slider-div .swiper-pagination-bullet-active-prev-prev {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}

.home-banner .sec-1 .slider-div .swiper-pagination-bullet-active-prev {
    background: #fff;
    width: 35px;
    border-radius: 10px;
}



@media (min-width:2001px) and (max-width:2600px) {
    .home-banner .sec-1 .slider-div {
        top: 450px;
    }

    .home-banner {
        height: 1233px;
    }
}

@media (min-width:1401px) and (max-width:1800px) {
    .home-banner .sec-1 .slider-div .main-div .title {
        font-size: 55px;
    }

    .home-banner .sec-1 .slider-div {
        top: 260px;
    }

}
@media (min-width:1601px) and (max-width:1800px) {
    .home-banner {
        height: 900px;
    }
}

@media (min-width:1401px) and (max-width:1600px) {
    .home-banner {
        height: 745px;
    }
}

@media (min-width:1201px) and (max-width:1400px) {
    .home-banner .sec-1 .slider-div .main-div .title {
        font-size: 45px;
    }

    .home-banner .sec-1 .slider-div .slidemain-t {
        font-size: 25px;
    }

    .home-banner .sec-1 .slider-div {
        top: 245px;
    }

    .home-banner .sec-1 .slider-div .swiper-wrapper {
        padding-bottom: 30px;
    }
    .home-banner .sec-1 .slider-div .slidemain-t::after {
        width: 154px;
    }
    .home-banner {
        height: 685px;
    }

}

@media (min-width:992px) and (max-width:1200px) {
    .home-banner .sec-1 .slider-div .main-div .title {
        font-size: 35px;
    }
    .home-banner .sec-1 .slider-div .slidemain-t {
        font-size: 18px;
    }
    .home-banner .sec-1 .slider-div .slidemain-t::after {
        width: 110px;
    }
    .home-banner .sec-1 .slider-div {
        top: 177px;
    }
    .home-banner .sec-1 .slider-div .swiper-wrapper {
        padding-bottom: 25px;
    }
    .home-banner {
        height: 550px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .home-banner .sec-1 .slider-div {
        top: 135px;
    }
    .home-banner .sec-1 .slider-div .main-div .title {
        font-size: 30px;
    }
    .home-banner .sec-1 .slider-div .slidemain-t {
        font-size: 16px;
    }
    .home-banner .sec-1 .slider-div .slidemain-t::after {
        width: 100px;
    }
    .home-banner .sec-1 .slider-div .swiper-wrapper {
        padding-bottom: 25px;
    }
    .home-banner {
        height: 450px;
    }
}


@media (min-width:576px) and (max-width:767.98px) {
    .home-banner .sec-1 .slider-div {
        top: 85px;
    }
    .home-banner .sec-1 .slider-div .slidemain-t {
        font-size: 14px;
    }
    .home-banner .sec-1 .slider-div .slidemain-t::after {
        width: 85px;
        height: 1px;
    }
    .home-banner .sec-1 .slider-div .main-div .title {
        font-size: 22px;
    }
    .home-banner .sec-1 .slider-div .swiper-wrapper {
        padding-bottom: 20px;
    }
    .home-banner {
        height: 350px;
    }

}

@media (min-width:401px) and (max-width:575px) {
    .home-banner .sec-1 .slider-div .slidemain-t {
        font-size: 12px;
    }
    .home-banner .sec-1 .slider-div {
        top: 70px;
    }
    .home-banner .sec-1 .slider-div .slidemain-t::after {
        width: 80px;
        height: 1px;
    }
    .home-banner .sec-1 .slider-div .main-div .title {
        font-size: 16px;
    }
    .home-banner .sec-1 .slider-div .main-div {
        margin-top: 18px;
    }
    .home-banner .sec-1 .slider-div .swiper-wrapper {
        padding-bottom: 20px;
    }
    .home-banner {
        height: 220px;
    }
}

@media (min-width:0px) and (max-width:400px) {
    .home-banner .sec-1 .slider-div .slidemain-t {
        font-size: 10px;
    }
    .home-banner .sec-1 .slider-div {
        top: 55px;
    }
    .home-banner .sec-1 .slider-div .slidemain-t::after {
        bottom: -3px;
        width: 60px;
        height: 1px;
    }
    .home-banner .sec-1 .slider-div .main-div .title {
        font-size: 14px;
    }
    .home-banner .sec-1 .slider-div .main-div {
        margin-top: 10px;
    }
    .home-banner .sec-1 .slider-div .swiper-wrapper {
        padding-bottom: 15px;
    }
    .home-banner {
        height: 200px;
    }
}   