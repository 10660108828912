.privacy-policy .heading-holder h2 {
    color: #BEBEBE;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

.privacy-policy .heading-holder p {
    color: #BEBEBE;
    font-size: 14px;
}
.home-return-pos{
    position: absolute;
    top: 15%;
    left: 0px;
    width: 100%;
}
.home-return-pos a{
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.privacy-policy .med-top{
    padding: 3% 2% 5% 2% !important;
}


@media(min-width:0px) and (max-width:767px) {
    .privacy-policy .heading-holder h2 {
        font-size: 15px;
    }
    .privacy-policy .heading-holder p {
        color: #BEBEBE;
        font-size: 12px;
    }
}