.filter-modal .modal-content {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
}

.filter-modal .bg-color {
    background: #E4E4E4 0% 0% no-repeat padding-box;
    border-radius: 10px 0px 0px 10px;
    padding: 50px;
    height: 100%;
}

.filter-modal .modal-body {
    padding: 0px;
}

.filter-modal .location-bg {
    background-image: url("../../../../../public/assets/images/studio/location-img.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15% 12% 31% 9%;
    border-radius: 0px 10px 10px 0px;
}

.filter-modal .location-bg-second {
    background-image: url("../../../../../public/assets/images/studio/prizing.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15% 12% 31% 9%;
    border-radius: 0px 10px 10px 0px;
}

.filter-modal .location-bg-third {
    background-image: url("../../../../../public/assets/images/studio/rating.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 13% 12% 31% 9%;
    border-radius: 0px 10px 10px 0px;
}

.filter-modal .location-bg-third p {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.filter-modal .heading-name h2 {
    font-size: 35px;
    font-weight: bold;
}

.filter-modal .nav-pills .nav-link {
    font-size: 16px;
    color: #000;
    border-radius: 0%;
    padding-left: 0%;
    padding-top: 12px;
    padding-bottom: 12px;
}

.filter-modal .nav-pills .border-line {
    border-top: 1px solid #000;
    width: 100px;
}

.filter-modal .nav-pills .nav-link.active {
    background-color: transparent;
    border-radius: 0%;
    color: #000;
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0%;
}

.filter-modal .form-label {
    font-size: 15px;
    font-weight: 500;
}

.filter-modal .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #000;
    font-size: 15px;
    border-radius: 0%;
    padding-left: 0%;
    background-color: transparent;
}

.filter-modal .form-control::placeholder {
    color: #707070;
}

.filter-modal .btn-search {
    background: #FFBE5C 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    border: none;
}

.filter-modal .btn-search:focus {
    background: #FFBE5C 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    border: none;
}

.filter-modal .circle-orr {
    border-radius: 50px;
    background: #BEBEBE 0% 0% no-repeat padding-box;
    width: 38px;
    height: 38px;
    margin:0px  auto 20px auto;
    text-align: center;
}

.filter-modal .circle-orr p {
    color: #FFF;
    font-size: 15px;
    padding-top: 8px;
    margin-top: 30px;
}

.filter-modal .distance-range p {
    font-size: 15px;
    font-weight: 500;
}

.filter-modal .input-range__slider {
    background: #DB0000;
    border: 1px solid #DB0000;
}

.filter-modal .input-range__track--active {
    background: #DB0000;
}

.filter-modal .form-check-input[type=checkbox] {
    height: 22px;
    width: 22px;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    border: 1px solid #707070;
    border-radius: 3px;
}

.filter-modal .form-check-label {
    font-size: 15px;
    color: #202020;
    margin-top: 4px;
    margin-left: 5px;
}

.filter-modal .star-icon {
    color: #FFBE5C;
}



.filter-modal .range-bar span.input-range__label-container {
    background-color: #FFBE5C;
    color: white;
    padding: 3px 12px;
}

.filter-modal .range-bar .input-range__label-container {
    position: relative;
    display: inline-block;
    background-color: #FFBE5C;
    color: white;
    padding: 3px 12px;
}

.filter-section {
    margin-bottom: 5%;
}

.filter-modal .range-bar .input-range__label-container::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #FFBE5C transparent transparent transparent;
}

.filter-modal .range-bar .input-range__label-container {
    left: -50%;
    position: relative;
    top: -17px;
}

.filter-modal .range-bar .slide {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px;
}

.filter-modal .range-bar .slide  span{
    font-weight: 500;
}

.filter-modal .range-bar span.input-range__label.input-range__label--min {
    display: none;
}

.filter-modal .range-bar span.input-range__label.input-range__label--max {
    display: none;
}

.filter-modal .range-bar {
    margin-top: 13%;
}
.filter-modal .modal-header{
    padding: 0px;
    border: 0px;
}
.filter-modal .modal-header .btn-close {
    position: absolute;
    padding: 0px;
    right: 25px;
    top: 25px;
    z-index: 999;
}

@media(min-width:0px) and (max-width:991px) {
    .filter-modal .heading-name h2 {
        font-size: 18px;
    }

    .filter-modal .distance-range p {
        font-size: 14px;
        font-weight: 500;
    }
    .filter-modal .btn-search {
        font-size: 12px;
    }
    .filter-modal .range-bar {
        margin-left: 0px;
        margin-top: 13%;
        text-align: center;
        width: 100%;
    }
    .filter-modal .bg-color {
        border-radius: 10px 10px 0px 0px;
    }
    .filter-modal .location-bg {
        border-radius: 0px 0px 10px 10px;
    }
    .filter-modal .range-bar .input-range__label-container {
        left: -50%;
        top: -8px;
        font-size: 11px;
    }
    .filter-modal .nav-pills .nav-link {
        font-size: 14px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .filter-modal .heading-name h2 {
        font-size: 18px;
    }

    .filter-modal .distance-range p {
        font-size: 14px;
        font-weight: 500;
    }

    .filter-modal .range-bar span.input-range__label-container {
        font-size: 10px;
    }
    .filter-modal .range-bar .input-range__label-container {
        top: -5px;
    }
  
}