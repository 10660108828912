.Home_about_us_section {
    padding: 5% 0%;
    position: relative;
    /* overflow-y: hidden !important;     */
    /* overflow: visible !important; */
}
   

.Home_about_us_section .heading-text-div {
    text-align: center;
}

.Home_about_us_section .heading-text-div .about-text {
    font-weight: 900;
    font-size: 92px;
    color: #fff;
    position: relative;
    top: 45px;
    transition: 0.3s;
}
.Home_about_us_section:hover .heading-text-div .about-text {
    top: 5px;
}

.Home_about_us_section .heading-text-div .us-text {
    font-weight: 900;
    font-size: 92px;
    color: #fff;
    position: relative;
    transition: 0.3s;
    top: -55px;
}
.Home_about_us_section:hover .heading-text-div .us-text {
    top: 0px;
}

.Home_about_us_section .heading-text-div .readmore {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    text-decoration: underline;
    position: relative;
    top: -60px;
    transition: 0.3s;
}
.Home_about_us_section:hover .heading-text-div .readmore {
    top: 0px;
}

.Home_about_us_section .overlay-images .leftdiv {
    position: absolute;
    width: 450px;
    height: 450px;
    aspect-ratio: 1/1;
    top: 0;
    left: 0;
    transition: 0.3s;
}

.Home_about_us_section:hover .overlay-images .leftdiv {
    top: 0;
    left: -300px;
}

.Home_about_us_section .overlay-images .leftdiv .leftside {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.Home_about_us_section .overlay-images .righttdiv {
    position: absolute;
    width: 450px;
    height: 450px;
    aspect-ratio: 1/1;
    top: 0px;
    right: 0px;
    transition: 0.3s;
}

.Home_about_us_section:hover .overlay-images .righttdiv {
    top: 0px;
    right: -300px;
}

.Home_about_us_section .overlay-images .righttdiv .rightside {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Home_about_us_section .middle-content {
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin-bottom: 0px;
    /* display: none; */
    transition: 0.3s;
    position: relative;  
    top: -60px; 
    opacity: 0;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    overflow: hidden;
   
}

.Home_about_us_section:hover .middle-content {
    /* display: block; */
    transition: 0.3s;
    position: relative;
    top: 0px;
    opacity: 1;
}


@media (min-width:1201px) and (max-width:1400px){
    .Home_about_us_section .overlay-images .leftdiv {
        width: 400px;
        height: 400px;
    }
    .Home_about_us_section .overlay-images .righttdiv {
        width: 400px;
        height: 400px;
    }
    .Home_about_us_section .heading-text-div .about-text {
        font-size: 85px;
    }
    .Home_about_us_section .heading-text-div .us-text {
        font-size: 85px;
    }
    .Home_about_us_section .heading-text-div .us-text {
        top: -85px;
    }
    .Home_about_us_section .heading-text-div .readmore {
        top: -88px;
    }
}


@media (min-width:992px) and (max-width:1200px){
    .Home_about_us_section .overlay-images .leftdiv {
        width: 300px;
        height: 300px;
    }
    .Home_about_us_section .overlay-images .righttdiv {
        width: 300px;
        height: 300px;
    }
    .Home_about_us_section .heading-text-div .about-text {
        font-size: 70px;
        top: 20px;
    }
    .Home_about_us_section .heading-text-div .us-text {
        font-size: 75px;
        top: -95px;
    }
    .Home_about_us_section .heading-text-div .readmore {
        font-size: 13px;
        top: -108px;
    }
    .Home_about_us_section:hover .overlay-images .leftdiv {
        left: -175px;
    }
    .Home_about_us_section:hover .overlay-images .righttdiv {
        top: 0px;
        right: -175px;
    }
}


@media (min-width:0px) and (max-width:991px){
    .Home_about_us_section .overlay-images .leftdiv {
        position: unset;
        width: 200px;
        height: 200px;
        margin:10px auto;
    }
    .Home_about_us_section .overlay-images .righttdiv {
        position: unset;
        width: 200px;
        height: 200px;
        margin:10px auto;
    }
    .Home_about_us_section .heading-text-div .about-text {
        font-size: 40px;
        position: unset;
    }
    .Home_about_us_section .heading-text-div .us-text {
        font-size: 40px;
        position: unset;
    }
    .Home_about_us_section .middle-content {
        font-size: 13px;
        position: unset;
        opacity: 1;
    }
    .Home_about_us_section .heading-text-div .readmore {
        font-size: 12px;
        position: unset;
    }
    .Home_about_us_section:hover .middle-content {
        position: unset;
    }

    .Home_about_us_section .overlay-images{
        display: flex;
    }
}