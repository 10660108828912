.Add_review_modal .ratingflex p{
    margin-bottom: 0px;
    font-size: 14px;
}
.Add_review_modal .ratingflex{
    margin-bottom: 20px;
}

.Add_review_modal .modal-footer {
    justify-content: start;
}

.Add_review_modal .cancelbtn , .Add_review_modal .cancelbtn:hover{
    background-color: #000;
    padding: 7px 25px;
    color: #fff;
    border: 0px;
    font-size: 14px;
}
.Add_review_modal .submitbtn , .Add_review_modal .submitbtn:hover {
    background-color: #FFBE5C;
    padding: 7px 25px;
    color: #000;
    border: 0px;
    font-size: 14px;
}