.montly-card {
    background: #646464 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin-top: 40px;
    transition: 0.3s;
    position: relative;
}

.montly-card:hover {
    background-color: #FFBE5C;
    transition: 0.3s;
    box-shadow: 0px 0px 12px 0px green;
}
.montly-card:hover::after {
    content: "";
    box-shadow: -3px 2px 10px 0px #c43ead;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}


.montly-card .form-start {
    padding: 20px 40px 20px 40px;
}

.montly-card .form-check-label {
    color: #FFF;
    font-size: 13px;
}

.montly-card:hover .form-check-label {
    color: #000;
}

.montly-card .form-check-input:checked {
    background-color: #FFBE5C;
    border-color: #FFBE5C;
}

.montly-card .form-check-input[type=checkbox] {
    border-radius: 0%;
}

.montly-card .form-check-input:checked[type=checkbox] {
  

}

.montly-card .image-plan {
    position: relative;
}

.montly-card .image-plan .plan-img {
    width: 100%;
    height: 100%;
}

.montly-card .image-plan .overlay-color {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #484848 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
}
.montly-card:hover .image-plan .overlay-color {
    background:transparent;
}

.montly-card .image-plan .text-plan {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.montly-card .image-plan .text-plan h2 {
    font-size: 30px;
    font-weight: bold;
    color: #FFF;
}

.montly-card .prize-text h2 {
    font-size: 50px;
    font-weight: bold;
    color: #FFF;
}

.montly-card:hover h2 {
    color: #000;
}

.montly-card .prize-text h3 {
    font-size: 12px;
    color: #FFF;
}

.montly-card .prize-text .select-plan {
    background: #484848 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #BEBEBE;
    font-size: 15px;
    border: none;
    padding: 6px 20px;
}

.montly-card:hover .select-plan {
    background-color: #000;
    color: #FFF;
    font-size: 15px;
    border: none;
    padding: 6px 20px;
}

@media(min-width:1200px) and (max-width:1400px) {
    .montly-card .image-plan .text-plan h2 {
        font-size: 24px;
    }

    .montly-card .prize-text h2 {
        font-size: 40px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .montly-card .image-plan .text-plan h2 {
        font-size: 24px;
    }

    .montly-card .prize-text h2 {
        font-size: 40px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .montly-card .image-plan .text-plan h2 {
        font-size: 24px;
    }

    .montly-card .prize-text h2 {
        font-size: 35px;
    }
}

@media(min-width:576px) and (max-width:767px) {
    .montly-card .image-plan .text-plan h2 {
        font-size: 20px;
    }

    .montly-card .prize-text h2 {
        font-size: 25px;
    }

    .montly-card .form-start {
        padding: 20px 15px 20px 16px;
    }

    .montly-card .form-check-label {
        font-size: 11px;
    }
}

@media(min-width:486px) and (max-width:577px) {
    .montly-card .image-plan .text-plan h2 {
        font-size: 30px;
    }

    .montly-card .prize-text h2 {
        font-size: 25px;
    }

    .montly-card .form-start {
        padding: 20px 15px 20px 16px;
    }

    .montly-card .form-check-label {
        font-size: 11px;
    }
}

@media(min-width:321px) and (max-width:486px) {
    .montly-card .image-plan .text-plan h2 {
        font-size: 25px;
    }

    .montly-card .prize-text h2 {
        font-size: 25px;
    }

    .montly-card .form-start {
        padding: 20px 15px 20px 16px;
    }

    .montly-card .form-check-label {
        font-size: 11px;
    }
}

@media(min-width:0px) and (max-width:375px) {
    .montly-card .image-plan .text-plan h2 {
        font-size: 20px;
    }

    .montly-card .prize-text h2 {
        font-size: 25px;
    }

    .montly-card .form-start {
        padding: 20px 15px 20px 16px;
    }

    .montly-card .form-check-label {
        font-size: 11px;
    }
}